import {
  getDeviceData,
  getMediaLink,
  getObjInAr,
  getParcelRequestData,
} from "../utils/utils";
import {
  createRequest,
  fetchPackagePhotoList,
  getSetup,
  sendChangedPass,
  sendLoginData,
  getTrackingDetails,
  getCargoTrackInfo,
  signUp,
  sendCodeSMS,
  sendPhoneForgot,
  sendCodeConfirmForget,
  sendNewPass,
  sendDeliveryAddress,
  loadCardMeta,
  setSecureCard,
  getTarrifsData,
  getRefferalData,
} from "./api-connector";
import { saveAuthDataLocal } from "./authMobile";

const { platform = "desktop" } = window["device"] || {};

export function getAddress() {
  return new Promise(function(resolve, reject) {
    createRequest("address", null, resolve, () => reject("error"));
  });
}

export function getNewPostList(id: number, carrierId: number) {
  const reqData: any = {
    ClassList: [
      {
        Class_id: 21510,
        TypeLoad: 0,
        needLinkObject: 0,
        nPage: 1,
        sizePage: 10000,
      },
    ],
    Filter: [
      {
        Class_id: 21510,
        Filter_id: 1,
        Property_id: 204,
        Operator_id: "=",
        Value: id,
      },
      {
        Class_id: 21510,
        Filter_id: 2,
        Property_id: -4,
        Operator_id: "=",
        Value: -5001,
      }, // Filter by state Active
      {
        Class_id: 21510,
        Filter_id: 3,
        Property_id: 373,
        Operator_id: "=",
        Value: carrierId,
      }, // filter by carrier NP 1620521
    ],
  };
  return new Promise((resolve, reject) => {
    createRequest("objList", reqData, resolve, reject);
  });
}

export function newAddressUSA(adr: any) {
  const {
    userId,
    userCountry,
    addressName,
    firstName,
    lastName,
    phoneNumber,
    deliveryType,
    street,
    state,
    zipCode,
    city,
    houseNumber,
    details,
  } = adr;
  const data = {
    tmp_object_list: [
      {
        OBJECT_ID: userId,
        Class_id: 22600,
        ObjectDB_id: userId,
      },
      {
        OBJECT_ID: -2,
        Class_id: -21210,
        ObjectDB_id: null,
      },
    ],
    tmp_object_property_det: [
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -309,
        VALUE: -1,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 229,
        VALUE: deliveryType,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 375,
        VALUE: details,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -222,
        VALUE: addressName,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -256,
        VALUE: zipCode,
        Oper: "I",
      },
      {
        // місто
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 364,
        VALUE: null,
        Oper: "I",
      },
      {
        //вулиця
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 233,
        VALUE: null,
        Oper: "I",
      },
      {
        //Country
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 201,
        VALUE: userCountry,
        Oper: "I",
      },
      {
        //State_code
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -630,
        VALUE: state,
        Oper: "I",
      },
      {
        //Street_name
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 886,
        VALUE: street,
        Oper: "I",
      },
      {
        //City_name
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 887,
        VALUE: city,
        Oper: "I",
      },
      {
        //Address_line1
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 888,
        VALUE: zipCode + " " + street,
        Oper: "I",
      },
      {
        //Address_last_line
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 890,
        VALUE: city + " " + state,
        Oper: "I",
      },
      {
        //Zip4
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 891,
        VALUE: null,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 234,
        VALUE: houseNumber ?? null,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 235,
        VALUE: null,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 363,
        VALUE: null,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -471,
        VALUE: -2,
        Oper: "I",
      },
      {
        OBJECT_ID: -2,
        Row_id: 1,
        Property_Master_id: -408,
        Property_id: -309,
        VALUE: -1,
        Oper: "I",
      },
      {
        OBJECT_ID: -2,
        Row_id: 1,
        Property_Master_id: -408,
        Property_id: -337,
        VALUE: -11009,
        Oper: "I",
      },
      {
        OBJECT_ID: -2,
        Row_id: 1,
        Property_Master_id: -408,
        Property_id: -389,
        VALUE: phoneNumber,
        Oper: "I",
      },
    ],
    tmp_object_property_scalar: [
      { OBJECT_ID: -2, Property_id: -232, VALUE: firstName },
      { OBJECT_ID: -2, Property_id: -225, VALUE: lastName },
      { OBJECT_ID: -2, Property_id: -222, VALUE: firstName + " " + lastName },
    ],
    tmp_object_link: [
      {
        ID: -1,
        Link_id: 30101,
        Object_Master_id: 2818144,
        Object_Slave_id: -2,
      },
    ],
  };

  return new Promise((resolve, reject) => {
    sendDeliveryAddress(data, resolve, reject, reject, reject);
  });
}

export function newAddress(data: any) {
  const {
    userId,
    userCountry,
    addressName,
    firstName,
    lastName,
    phoneNumber,
    deliveryType,
    street,
    zipCode,
    city,
    postDepartment,
    build,
    flat,
  } = data;
  const cargoAddressData = {
    tmp_object_list: [
      { OBJECT_ID: userId, Class_id: 22600, ObjectDB_id: userId },
      { OBJECT_ID: -2, Class_id: -21210, ObjectDB_id: null },
    ],
    tmp_object_property_scalar: [
      { OBJECT_ID: -2, Property_id: -232, VALUE: firstName },
      { OBJECT_ID: -2, Property_id: -225, VALUE: lastName },
      { OBJECT_ID: -2, Property_id: -222, VALUE: firstName + " " + lastName },
    ],
    tmp_object_property_det: [
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -309,
        VALUE: -1,
        Oper: "I",
      }, // AddressID
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 229,
        VALUE: deliveryType,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -471,
        VALUE: -2,
        Oper: "I",
      }, // contactId
      {
        OBJECT_ID: -2,
        Row_id: 1,
        Property_Master_id: -408,
        Property_id: -309,
        VALUE: -1,
        Oper: "I",
      },
      {
        OBJECT_ID: -2,
        Row_id: 1,
        Property_Master_id: -408,
        Property_id: -337,
        VALUE: -11009,
        Oper: "I",
      },
      {
        OBJECT_ID: -2,
        Row_id: 1,
        Property_Master_id: -408,
        Property_id: -389,
        VALUE: phoneNumber,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -222,
        VALUE: addressName,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: -256,
        VALUE: zipCode,
        Oper: "I",
      },
      // Set address Request data
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 364,
        VALUE: city,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 233,
        VALUE: street,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 234,
        VALUE: build || null,
        Oper: "I",
      },
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 235,
        VALUE: flat || null,
        Oper: "I",
      },
      // Set currierOffice request data
      {
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 363,
        VALUE: postDepartment || null,
        Oper: "I",
      },
      {
        //Country
        OBJECT_ID: userId,
        Row_id: 1,
        Property_Master_id: 230,
        Property_id: 201,
        VALUE: userCountry,
        Oper: "I",
      },
    ],
    tmp_object_link: [
      { ID: -1, Link_id: 30101, Object_Master_id: userId, Object_Slave_id: -2 },
    ],
  };
  return new Promise((resolve, reject) => {
    sendDeliveryAddress(cargoAddressData, resolve, reject, reject, reject);
  });
}

export function getCarrierId() {
  return new Promise((resolve, reject) => {
    createRequest(
      "objList",
      {
        ClassList: [{ Class_id: 22200, TypeLoad: 0 }],
        Filter: [{ Property_id: 246, Operator_id: "=", Value: 11046 }],
      },
      resolve,
      reject
    );
  });
}

export function getStateList(country: number) {
  const reqData = {
    ClassList: [
      {
        Class_id: 21200,
        TypeLoad: 0,
        needLinkObject: 0,
        nPage: 1,
        sizePage: 10000,
      },
    ],
    Filter: [
      {
        Class_id: 21200,
        Filter_id: 1,
        Node_id: 1,
        Property_id: 201,
        Operator_id: "=",
        Value: country,
      },
      {
        Class_id: 21200,
        Filter_id: 2,
        Node_id: 1,
        Property_id: -4,
        Operator_id: "=",
        Value: -5001,
      }, // Filter by state Active
    ],
  };
  return new Promise((resolve, reject) => {
    createRequest(
      "objList",
      reqData,
      resolve,
      reject // error callback
    );
  });
}

export function getCityList(id: number) {
  const reqData: any = {
    ClassList: [
      {
        Class_id: 21400,
        TypeLoad: 0,
        needLinkObject: 0,
        nPage: 1,
        sizePage: 10000,
      },
    ],
    Filter: [
      {
        Class_id: 21400,
        Filter_id: 1,
        Node_id: 1,
        Property_id: 202,
        Operator_id: "=",
        Value: id,
      },
      {
        Class_id: 21400,
        Filter_id: 2,
        Node_id: 1,
        Property_id: -4,
        Operator_id: "=",
        Value: -5001,
      }, // Filter by state Active
    ],
  };
  return new Promise((resolve, reject) => {
    createRequest(
      "objList",
      reqData,
      resolve,
      reject // error callback
    );
  });
}

export function getStreetList(id: number) {
  const reqData = {
    ClassList: [{ Class_id: 21400, TypeLoad: 0, needLinkObject: 1 }],
    PropertyList: [{ Class_id: 21400, Property_id: 205 }],
    PropertyDetailList: [
      { Class_id: 21400, PropertyMaster_id: 205, Property_id: -309 },
      { Class_id: 21400, PropertyMaster_id: 205, Property_id: -222 },
      // {"Class_id": 21400,"PropertyMaster_id":205,"Property_id":381} // state
    ],
    Filter: [
      {
        Class_id: 21400,
        Filter_id: 1,
        Node_id: 1,
        Property_id: -1,
        Operator_id: "=",
        Value: id,
      },
    ],
    PropertyDetailFilter: [
      {
        Class_id: 21400,
        Property_id: 205,
        PropertyDetail_id: 381,
        Operator_id: "=",
        Value: -5001,
      }, // Filter propDet by state Active
    ],
  };
  return new Promise((resolve, reject) => {
    createRequest(
      "objList",
      reqData,
      resolve,
      reject // error callback
    );
  });
}

export function deleteAddress(addressId: number, userId: number) {
  return new Promise((resolve, reject) => {
    createRequest(
      "objEdit",
      {
        tmp_object_list: [
          { OBJECT_ID: userId, Class_id: 22600, ObjectDB_id: userId },
        ],
        tmp_object_property_det: [
          {
            OBJECT_ID: userId,
            Row_id: 1,
            Property_Master_id: 230,
            Property_id: -309,
            VALUE: addressId,
            Oper: "D",
          },
        ],
      },
      resolve,
      reject
    );
  });
}

export function setDefaultAddress(addressId: number, userId: number) {
  return new Promise((resolve, reject) => {
    createRequest(
      "objEdit",
      {
        tmp_object_list: [
          { OBJECT_ID: userId, Class_id: 22110, ObjectDB_id: userId },
        ],
        tmp_object_property_det: [
          {
            OBJECT_ID: userId,
            Row_id: 1,
            Property_Master_id: 230,
            Property_id: -309,
            VALUE: addressId,
            Oper: "U",
          },
          {
            OBJECT_ID: userId,
            Row_id: 1,
            Property_Master_id: 230,
            Property_id: -298,
            VALUE: 1,
            Oper: "U",
          },
        ],
      },
      resolve,
      reject
    );
  });
}

export function getParcelImages(id: number) {
  return new Promise(function(resolve, reject) {
    fetchPackagePhotoList(
      id,
      (res: any) => {
        resolve(res.map((img: any) => getMediaLink(img.photo)));
      },
      () => reject([]),
      () => reject([])
    );
  });
}

export function getParcelDataReq(id: number) {
  // Get package details by packageId
  const data = {
    parcelData: {},
    deliveryProducts: [],
  };
  const requestData = getParcelRequestData(id);
  return new Promise(function(resolve, reject) {
    createRequest(
      "objList",
      requestData,
      (res: any) => {
        if (!res["N1024800__1"]) {
          return resolve(data);
        }
        const productsList = res["N3024800_0477"] || [];
        data.parcelData = res["N1024800__1"][0];
        data.deliveryProducts = productsList.map((el: any) => ({
          price: el.pr249,
          count: el.pr256,
          name: el.pr474,
          id: el.pr_309,
        }));
        resolve(data);
      },
      () => reject(data)
    );
  });
}

export function getCountryToRates(userCountry: number) {
  const data = {
    dutyFreeLimit: 168,
    freeTaxLimit: 112,
    customsService: 5,
  };
  return new Promise(function(resolve, reject) {
    createRequest(
      "objList",
      {
        // requestData
        ClassList: [{ Class_id: 21100, TypeLoad: 0, needLinkObject: 0 }],
        PropertyList: [
          { Class_id: 21100, Property_id: 503 }, // Безпошлинный лимит
          { Class_id: 21100, Property_id: 398 }, // Не облагается НДС
          { Class_id: 21100, Property_id: 504 }, // Услуга таможенного оформления
        ],
        Filter: [
          {
            Class_id: 21100,
            Filter_id: 1,
            Node_id: 1,
            Property_id: -1,
            Operator_id: "=",
            Value: userCountry,
          }, // User country
        ],
      },
      (responseData: any) => {
        if ("N1021100__1" in responseData) {
          const countryData = responseData["N1021100__1"][0];
          data.dutyFreeLimit = +countryData.pr503;
          data.freeTaxLimit = +countryData.pr398;
          data.customsService = +countryData.pr504;
        }
        resolve(data);
      },
      () => reject(data)
    );
  });
}

export function getDeliveryCost(id: number) {
  const requestData = {
    ClassList: [{ Class_id: 24640, TypeLoad: 0, needLinkObject: 0 }],
    PropertyList: [
      // Warn: PropertyList must be the same as loading productCategory on productGroupChanged
      { Class_id: 24640, Property_id: 505 }, // DeliveryCost
      { Class_id: 24640, Property_id: 518 }, // customs product cost
      { Class_id: 24640, Property_id: 487 }, // customs payment
      { Class_id: 24640, Property_id: 606 }, // 606 - Предварительная полная стоимость услуги выкупа
    ],
    Filter: [
      {
        Class_id: 24640,
        Filter_id: 1,
        Node_id: 1,
        Property_id: -1,
        Operator_id: "=",
        Value: id,
      },
    ],
  };
  const res = {
    roughDeliveryCost: 0,
    globalDeliveryWeight: 0,
    globalDeliveryTariff: 0,
  };
  return new Promise(function(resolve, reject) {
    createRequest(
      "objList",
      requestData,
      (responseData: any) => {
        if ("N1024640__1" in responseData) {
          // pr505: "{"result":[{"TypeCost_id":11518,"Cost":0.00,"Base":null,"Tariff":null,"TotalCost":10.80},{"TypeCost_id":11519,"Cost":1.00,"Base":null,"Tariff":1.00,"TotalCost":10.80},{"TypeCost_id":11520,"Cost":8.80,"Base":1.00,"Tariff":8.80,"TotalCost":10.80},{"TypeCost_id":11521,"Cost":1.00,"Base":17.65,"Tariff":0.02,"TotalCost":10.80},{"TypeCost_id":11555,"Cost":0.00,"Base":0.00,"Tariff":0.00,"TotalCost":10.80}]}"
          const shoppingDB = responseData["N1024640__1"][0];
          if (!shoppingDB["pr505"]) return resolve(res);
          const costData = JSON.parse(shoppingDB["pr505"]);
          const roughDeliveryCostFound = getObjInAr(
            costData.result,
            "TypeCost_id",
            11520
          );
          if (roughDeliveryCostFound) {
            res.roughDeliveryCost = roughDeliveryCostFound.Cost;
            res.globalDeliveryWeight = roughDeliveryCostFound["Base"];
            res.globalDeliveryTariff = roughDeliveryCostFound["Tariff"];
          } else {
            console.warn("RoughDeliveryCost not found " + id);
          }
        }
        resolve(res);
      },
      () => reject(res)
    );
  });
}

export function changeAddress(id: number, address: number) {
  const requestData: any = {
    tmp_object_list: [{ OBJECT_ID: id, Class_id: 24800, ObjectDB_id: id }],
    tmp_object_property_scalar: [
      { OBJECT_ID: id, Property_id: 489, VALUE: address, Oper: "U" },
    ],
  };

  return new Promise(function(resolve, reject) {
    createRequest(
      "objEdit",
      requestData,
      (res: any) => {
        resolve(res);
      }, // Success callback
      () => reject(false)
    );
  });
}

export function changeInsurance(id: number, val: boolean) {
  const requestData: any = {
    tmp_object_list: [{ OBJECT_ID: id, Class_id: 24800, ObjectDB_id: id }],
    tmp_object_property_scalar: [
      { OBJECT_ID: id, Property_id: 595, VALUE: val, Oper: "U" },
    ],
  };

  return new Promise(function(resolve, reject) {
    createRequest(
      "objEdit",
      requestData,
      (res: any) => {
        resolve(res);
      }, // Success callback
      () => reject(false)
    );
  });
}

export function saveEditedProduct(
  id: number,
  products: any[],
  operation: string
) {
  // save edited products
  const requestData: any = {
    tmp_object_list: [{ OBJECT_ID: id, Class_id: 24800, ObjectDB_id: id }],
    tmp_object_property_det: [],
  };
  products.forEach((product) => {
    // set product list (propDet)
    requestData.tmp_object_property_det.push(
      {
        OBJECT_ID: id,
        Row_id: product.index + 1,
        Property_Master_id: 477,
        Property_id: -309,
        VALUE: product.id,
        Oper: operation,
      },
      {
        OBJECT_ID: id,
        Row_id: product.index + 1,
        Property_Master_id: 477,
        Property_id: 474,
        VALUE: product.name,
        Oper: operation,
      }, // name
      {
        OBJECT_ID: id,
        Row_id: product.index + 1,
        Property_Master_id: 477,
        Property_id: 256,
        VALUE: product.count,
        Oper: operation,
      },
      {
        OBJECT_ID: id,
        Row_id: product.index + 1,
        Property_Master_id: 477,
        Property_id: 249,
        VALUE: product.price,
        Oper: operation,
      } // price
    );
  });

  return new Promise(function(resolve, reject) {
    createRequest(
      "objEdit",
      requestData,
      (res: any) => {
        resolve(res);
      }, // Success callback
      () => reject(false)
    );
  });
}

type Estimate = {
  id: number;
  text: string;
  type: string;
  rate: number;
};
export function saveEstimate({ id, text, type, rate }: Estimate) {
  const types = {
    product: 11834,
    delivery: 11835,
  };
  const requestData: any = {
    tmp_object_list: [{ OBJECT_ID: id, Class_id: 24800, ObjectDB_id: id }],
    tmp_object_property_det: [
      // { "OBJECT_ID": id, "Row_id": -2, "Property_Master_id": 746, "Property_id": -309, "VALUE": -2, "Oper": "I" },
      {
        OBJECT_ID: id,
        Row_id: -2,
        Property_Master_id: 746,
        Property_id: -314,
        VALUE: text,
        Oper: "I",
      },
      // { "OBJECT_ID": id, "Row_id": -2, "Property_Master_id": 746, "Property_id": -290, "VALUE": "Invalid date", "Oper": "I" },
      {
        OBJECT_ID: id,
        Row_id: -2,
        Property_Master_id: 746,
        Property_id: 745,
        VALUE: types[type],
        Oper: "I",
      },
      {
        OBJECT_ID: id,
        Row_id: -2,
        Property_Master_id: 746,
        Property_id: 747,
        VALUE: rate,
        Oper: "I",
      },
    ],
  };

  return new Promise(function(resolve, reject) {
    createRequest(
      "objEdit",
      requestData,
      (res: any) => {
        resolve(res);
      }, // Success callback
      () => reject(false)
    );
  });
}

export interface BaseTarrif {
  Cost: number;
  Balance?: number;
  Base?: number;
  Tariff?: number;
  ToPay?: number;
  TotalCost?: number;
  TypeCost_id?: number;
}
export const tarrifsTypes = {
  11518: "innerDelivery",
  11519: "werehouse",
  11520: "countryDelivery",
  11521: "insurance",
  11555: "localDelivery",
  11791: "combine",
  11792: "divide",
  11793: "additionalBox",
  11794: "additionalPhoto",
  11670: "productPrice",
  11671: "buyout",
  11672: "productTax",
  11837: "consult",
};
const baseTarrif: BaseTarrif = {
  Cost: 0,
};

export function getParcelPrice(
  id: number,
  activeAddressId = null,
  insurance = false
) {
  // {Balance: 569.31,Base: null,Cost: 0,Tariff: null,ToPay: 0,TotalCost: 3.64,TypeCost_id: 11518}
  const res = {
    innerDelivery: baseTarrif,
    werehouse: baseTarrif,
    countryDelivery: baseTarrif,
    insurance: baseTarrif,
    localDelivery: baseTarrif,
    combine: baseTarrif,
    divide: baseTarrif,
    additionalBox: baseTarrif,
    additionalPhoto: baseTarrif,
  };
  const reqData = {
    IsConfirm: 0,
    PackageId: id,
    Pr302: 11102, // avia
    ToCountryId: null,
    AddressDetId: activeAddressId,
    is11521: !!insurance,
  };
  return new Promise(function(resolve, reject) {
    createRequest(
      "packageDeliveryCost",
      reqData,
      ({ result = null }: any) => {
        if (result) {
          //@ts-ignore
          result.map(
            (tarrif: any) => (res[tarrifsTypes[tarrif.TypeCost_id]] = tarrif)
          );
        }
        resolve(res);
      },
      () => reject(res)
    );
  });
}

export default function getShoppingCost(id: number) {
  const res = {
    innerDelivery: baseTarrif,
    productPrice: baseTarrif,
    productTax: baseTarrif,
    buyout: baseTarrif,
  };

  return new Promise(function(resolve, reject) {
    createRequest(
      "shoppingCost",
      { DocId: id },
      ({ sm_sel_cost_itrade_doc = null }: any) => {
        // Success callback {"sm_sel_cost_itrade_doc_total":[{Balance:569.31,ToPay: 0,TotalCost: 2.29,ServiceCostDraft: 0.02}]}
        if (sm_sel_cost_itrade_doc) {
          //@ts-ignore
          sm_sel_cost_itrade_doc.map(
            (tarrif: any) => (res[tarrifsTypes[tarrif.TypeCost_id]] = tarrif)
          );
          resolve(res);
        } else console.error("Incorrect response data Shopping cost");
      },
      () => reject(res)
    );
  });
}

export function getBasePrices() {
  const res = {
    combinePrice: 1,
    dividePrice: 1,
  };
  return new Promise(function(resolve) {
    getSetup(
      // get setup data
      [77, 78],
      (responseData: any) => {
        // Success callback
        res.combinePrice = responseData[0].Value || 0;
        res.dividePrice = responseData[1].Value || 0;
        resolve(res);
      }
    );
  });
}

interface ChangePassData {
  username: string;
  phone: string;
  newPassword: string;
  oldPassword: string;
}
export function changePassword({
  username,
  phone,
  newPassword,
  oldPassword,
}: ChangePassData) {
  const passData = {
    username,
    oldPassword,
    newPassword,
  };
  return new Promise(function(resolve, reject) {
    sendChangedPass(
      passData,
      // Response example: { Message: "Password successfully changed", Status: "Success" }
      (responseData: any) => {
        if (responseData.Status === "Success") {
          // get device info
          const deviceData = {
            screenSize: "",
            dpi: 0,
          };
          if (screen.width) {
            const width = screen.width ? screen.width : "";
            const height = screen.height ? screen.height : "";
            deviceData.screenSize += "" + width + " x " + height;
          }

          // reLogin (update authorization token)
          sendLoginData(
            { username: phone, password: newPassword },
            deviceData,
            // Success callback function
            (responseData: any) => {
              // Success callback
              saveAuthDataLocal(responseData);
            },
            () => {
              // error callback
              // TODO
              // this.$root.$emit(eventsRegister.addMessage, { type: 'error', message: lcl.serverError } )
            }
          );

          resolve(responseData);
        }
      },
      (responseData: any = {}) => {
        // error callback
        const errorMsg: string = responseData.message || responseData.Message;
        reject(errorMsg);
      }
    );
  });
}

export function getTrackingStatus(parcelId: string) {
  const reqData = {
    filters: [
      { sKey: "iParcel_id", condition: `${parcelId}` },
      { sKey: "Lang_id", condition: "-39" },
    ],
  };
  return new Promise(function(resolve, reject) {
    getTrackingDetails(
      reqData,
      (res: any) => {
        resolve(res.sm_api_track_parcels_itrade_res || []);
      },
      reject
    );
  });
}

export function parseShoppingCost(data: any) {
  const res = {
    innerDelivery: baseTarrif,
    productPrice: baseTarrif,
    productTax: baseTarrif,
    buyout: baseTarrif,
  };
  //@ts-ignore
  data.map((tarrif: any) => (res[tarrifsTypes[tarrif.TypeCost_id]] = tarrif));
  return res;
}

export function getTrackingStatusList(parcelId: string) {
  return new Promise(function(resolve, reject) {
    getCargoTrackInfo(
      parcelId,
      (res: any) => {
        resolve(res);
        // Success callback
        //   if (responseData && responseData.length > 0) {
        //     // track data found
        //     let trackDataResult = responseData;
        //     // Loop track results from end to start
        //     let resultLen = trackDataResult.length;
        //     let invertedTrackResult = [];
        //     for (let i = resultLen; i > 0; i--) {
        //       let curTrackItem = trackDataResult[i - 1];
        //       invertedTrackResult.push(curTrackItem);
        //     }
        //     this.cargoTrackData = invertedTrackResult;
        //   } else this.noResultText = this.$t('noResultsFound');
      },
      reject
    );
  });
}

export const serviceTypes = {
  68: "buyoutPercent",
  69: "minBuyoutService",
  71: "insurancePercent",
  [-71]: "insuranceMinCost",
  77: "combinePrice",
  78: "dividePrice",
  79: "repackPrice",
  80: "photoPrice",
  83: "consultPrice",
  91: "volumeCoff",
  515: "minDeliveryTime",
  516: "maxDeliveryTime",
};
export function getSettings() {
  const res = {
    buyoutPercent: 0,
    minBuyoutService: 0,
    insurancePercent: 0,
    insuranceMinCost: 0,
    combinePrice: 0,
    dividePrice: 0,
    repackPrice: 0,
    photoPrice: 0,
    consultPrice: 0,
    volumeCoff: 0,
  };
  return new Promise((resolve, reject) => {
    createRequest(
      "settings",
      null,
      ({ cm_setup_value }: any) => {
        // sm_sel_cost_itrade_doc.map((tarrif: any) => res[tarrifsTypes[tarrif.TypeCost_id]] = tarrif)
        if (cm_setup_value) {
          //@ts-ignore
          cm_setup_value.map(
            (t: any) => (res[serviceTypes[t.Setup_id]] = +t.Value)
          );
        }
        resolve(res);
      },
      () => reject
    );
  });
}

export function sendRefferal(customerId: number, refferalCode: string) {
  return new Promise((resolve) => {
    createRequest(
      "objEdit",
      {
        tmp_object_list: [
          {
            OBJECT_ID: customerId,
            Class_id: 22600,
            ObjectDB_id: customerId,
          },
        ],
        tmp_object_property_scalar: [
          {
            OBJECT_ID: customerId,
            Property_id: 669,
            VALUE: refferalCode,
          },
        ],
      },
      resolve
    );
  });
}

export function toggleShareProducts(customerId: number, value: boolean) {
  return new Promise((resolve) => {
    createRequest(
      "objEdit",
      {
        tmp_object_list: [
          { OBJECT_ID: customerId, Class_id: 22600, ObjectDB_id: customerId },
        ],
        tmp_object_property_scalar: [
          { OBJECT_ID: customerId, Property_id: 830, VALUE: value ? 1 : 0 },
        ],
      },
      resolve
    );
  });
}

export function toggleShareByProduct(parcelId: number, value: boolean) {
  return new Promise((resolve) => {
    createRequest(
      "objEdit",
      {
        tmp_object_list: [
          { OBJECT_ID: parcelId, Class_id: 24640, ObjectDB_id: parcelId },
        ],
        tmp_object_property_scalar: [
          { OBJECT_ID: parcelId, Property_id: 830, VALUE: value ? 1 : 0 },
        ],
      },
      resolve
    );
  });
}

export function getInnerPackages() {
  return new Promise((resolve, reject) => {
    createRequest(
      "innerPackages",
      null,
      ({ sm_package_det = [] }: any) => {
        if (sm_package_det.length) {
          resolve(sm_package_det);
        }
        resolve([]);
      },
      reject
    );
  });
}

export function login(formAuthData: any) {
  return new Promise((resolve, reject) => {
    sendLoginData(
      formAuthData,
      getDeviceData(),
      // Success callback function
      (responseData: any) => {
        resolve(responseData);
      },
      reject
    );
  });
}

export function sendSignUp(
  username: string,
  phoneNumber: string,
  password: string,
  countryCode: string
) {
  return new Promise((resolve, reject) => {
    signUp(
      {
        Property_232: username, // name
        Property_537: phoneNumber, // phone
        Property_294: password, // pass
        Property201: countryCode, // countryCode
        OS_Name: platform,
      },
      // Success callback function
      (responseData: any) => {
        resolve(responseData);
      },
      reject
    );
  });
}

export function sendSignUpConfirm(
  phoneNumber: string,
  code: string,
  countryCode: number
) {
  return new Promise((resolve, reject) => {
    sendCodeSMS(
      {
        Property_537: phoneNumber,
        Property466: code,
        Property201: countryCode,
      },
      getDeviceData(),
      // Success callback function
      (responseData: any) => {
        resolve(responseData);
      },
      reject
    );
  });
}

export function resetPasswordSMS(phoneNumber: string, countryCode: number) {
  return new Promise((resolve, reject) => {
    sendPhoneForgot(
      {
        Property_537: phoneNumber,
        OS_Name: platform,
        Property201: countryCode,
      },
      (responseData: any) => {
        resolve(responseData);
      },
      reject
    );
  });
}

export function resetPasswordConfirm(
  phoneNumber: string,
  confirmCode: string,
  countryCode: number
) {
  return new Promise((resolve, reject) => {
    sendCodeConfirmForget(
      {
        Property_537: phoneNumber,
        Property466: confirmCode,
        Property201: countryCode,
      },
      (responseData: any) => {
        resolve(responseData);
      },
      reject
    );
  });
}

export function resetPasswordNewPass(
  phoneNumber: string,
  confirmCode: string,
  newPassword: string,
  countryCode: number
) {
  return new Promise((resolve, reject) => {
    sendNewPass(
      {
        Property_537: phoneNumber,
        Property466: confirmCode,
        Property_294: newPassword,
        Property201: countryCode,
      },
      getDeviceData(),
      (responseData: any) => {
        resolve(responseData);
      },
      reject
    );
  });
}

export async function getTarrifs() {
  const data = await getTarrifsData();

  const [dataUA, dataUSA, dataUK, dataCanada] = data ?? [];

  return [dataUA, dataUSA, dataUK, dataCanada];
}

export async function gerRefferalsPrice() {
  const data = await getRefferalData();

  const [usa, ua] = data.N1023860__1;

  return {
    USA: usa.pr667,
    UA: ua.pr667,
  };
}

export async function getDeliveryRates() {
  const getCN = () =>
    new Promise((resolve, reject) => {
      createRequest(
        "deliveryCost",
        {
          FromCountryId: 287, // CN this.countryFrom,
          ToCountryId: 285, // Ukraine
          TypeRouteId: null,
          Pr302: 11102, // Air - transportation type
          pr409: null,
          CustomerId: null,
        },
        (responseData: any) => resolve(responseData.Value),
        reject
      );
    });
  const getUSA = () =>
    new Promise((resolve, reject) => {
      createRequest(
        "deliveryCost",
        {
          FromCountryId: 284, // USA this.countryFromShop
          ToCountryId: 285, // Ukraine
          TypeRouteId: null,
          Pr302: 11102, // Air - transportation type
          pr409: null,
          CustomerId: null,
        },
        (responseData: any) => resolve(responseData.Value),
        reject
      );
    });
  // const getTurkey = () => new Promise((resolve, reject) => {
  //     createRequest(
  //         "deliveryCost",
  //         {
  //             FromCountryId: 294,
  //             ToCountryId: 285, // Ukraine
  //             TypeRouteId: null,
  //             Pr302: 11102, // Air - transportation type
  //             pr409: null,
  //             CustomerId: null,
  //         },
  //         (responseData: any) => resolve(responseData.Value),
  //         reject
  //     );
  // })
  return await Promise.all([
    getUSA(),
    getCN(),
    // getTurkey()
  ]);
}

export function getUserData() {
  return new Promise((resolve, reject) => {
    createRequest("initData", null, resolve, reject);
  });
}

export function getSharedParcelList() {
  return new Promise((resolve, reject) => {
    createRequest("sharedparcels", null, resolve, reject);
  });
}

export function getParcelList() {
  return new Promise((resolve, reject) => {
    createRequest(
      "parcels",
      null,
      resolve,
      reject // server error callback
    );
  });
}

export function getArchiveParcelList() {
  return new Promise((resolve, reject) => {
    createRequest(
      "parcelsarchive",
      null,
      resolve,
      reject // server error callback
    );
  });
}

export function getSavedCards(userId: number) {
  return new Promise((resolve, reject) => {
    createRequest(
      "savedCards",
      { CustomerId: userId },
      resolve,
      reject // error callback
    );
  });
}

export function getLastUpdates() {
  return new Promise((resolve, reject) => {
    createRequest("lastUpdates", null, resolve, reject);
  });
}

export function getArchiveLastUpdates() {
  return new Promise((resolve, reject) => {
    createRequest("lastUpdatesArchive", null, resolve, reject);
  });
}

export function getArchiveUserData() {
  return new Promise((resolve, reject) => {
    createRequest("initDataArchive", null, resolve, reject);
  });
}

export function getPaymentMetaData({
  userId,
  lastFour,
  expMonth,
  expYear,
}: any) {
  const params = `?customer_id=${userId}&last4=${lastFour}&exp_month=${expMonth}&exp_year=${expYear}`;
  return new Promise<{}>((resolve, reject) => {
    loadCardMeta(params, resolve, reject);
  });
}

export function setSecureCardId(data: {
  PaymentId: string;
  StripeCardId?: string;
}) {
  return new Promise((resolve, reject) => {
    setSecureCard(
      {
        ...data,
      },
      resolve,
      reject,
      reject
    );
  });
}

export const getUserDataByIP = async () => {
  try {
    const res = await fetch("https://ipapi.co/json/");
    const { country_code, country_calling_code } = await res.json();

    return {
      phoneCode: country_calling_code,
      countryCode: country_code,
    };
  } catch (err) {
    console.log("IP FETCH ERROR", err);
    return null;
  }
};
