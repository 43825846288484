export interface AppState {
    'appVersion': string;
    'isDebug': boolean;
    'lang': string;
    'platform': string;
    'webSocketConn': any;
    'isPopupActive': boolean;
    'isPopupSlideActive': boolean;
    'isOnline': boolean;
    'currentView': string;
    'currentViewName': string;
    'activeTabIdx': number;
    'searchText': string;
    'routeparam': string;
    'isUserAuth': boolean;
    'appPath': string; // mobile app requires
    'isSidebarMobileOpen': boolean;
    'isScrollLocked': boolean;
    'isBaseDataLoading': boolean; // Dashboard init data
    'isCargosDataLoaded': boolean;
    'isParcelsDataLoaded': boolean;
    'isBankCardsLoaded': boolean;
    'isReferralsDataLoaded': boolean;
    'isParcelsEditModeActive': boolean;
    'isNotificationChanged': boolean;
    'isAppGuideActive': boolean;
    'isSettingBtnActive': boolean;
    'isNewUser': boolean; // show help menu item on top of menu for new users for 30 days
    'isPopupMessageLast': boolean;
    'isLoading': false;
    'isConnectionError': false; // API error
    'isBuyoutOpened': boolean;
    'isDeliveryOpened': boolean;
    'filledProduct': any;
    'pushNotification': any;
    'sharedProductId': number | null;
    'sharedShopId': number | null;

    'isCargoInsuranceChecked': boolean | null;
    'activeCargoAddressObj': any | null;
    'priceUSA': number;
    'priceCN': number;
}


// Observable reactive data object
export const appState: AppState = {
    // appVersion: '2.27.350.030', for web
    appVersion: '2.27.445',
    isDebug: true,
    lang: 'uk-UA',
    platform: 'android', // or ios
    webSocketConn: null, // websocket connection shared
    isPopupActive: false,
    isPopupSlideActive: false,
    isOnline: true,
    currentView: 'home',
    currentViewName: 'home',
    activeTabIdx: 0, // tab index start from 0
    searchText: '',
    routeparam: '',
    isUserAuth: false,
    appPath: '',
    isSidebarMobileOpen: false,
    isScrollLocked: false,
    isBaseDataLoading: true, // Dashboard init data
    isCargosDataLoaded: false,
    isParcelsDataLoaded: false,
    isBankCardsLoaded: false,
    isReferralsDataLoaded: false,
    isParcelsEditModeActive: false,
    isNotificationChanged: false,
    isAppGuideActive: true,
    isSettingBtnActive: false,
    isNewUser: false,
    isPopupMessageLast: false,
    isLoading: false,
    isConnectionError: false,
    isBuyoutOpened: false,
    isDeliveryOpened: false,
    filledProduct: {},
    pushNotification: null,
    sharedProductId: null,
    sharedShopId: null,

    isCargoInsuranceChecked: null,
    activeCargoAddressObj: null,
    priceUSA: 0,
    priceCN: 0,
}


// export const langList: any = Object.freeze({'uk-UA':'UA', 'ru-RU':'RU', 'en-US':'EN'})
export const langList: any = Object.freeze({'uk-UA':'UA', 'ru-RU':'RU'})

export default appState