export interface Customer {
	"ID": number;
	"AccessLevel": string; // "0000"
	"pr201": string; // Country ISO (UA)
	"pr201_Name": string; // country name
	"pr_222": string;
	"pr236": number;
	"pr226_Name": string;
	"pr315": number;
	"pr376": boolean;
	"pr525": boolean; // nonstop shopping setting
	"pr526": boolean; // insurance shopping setting
	"pr568": string; // last view messages/events date
	"pr_625": number; // refferal code
	"st_11237": string;
	"st_11010": string;
	"st_11239": string;
	"st_11368": string;
	"pr512": any;
	"isDefCurrency": boolean;
	"Range_From": boolean;
	"Range_To": boolean;
}

export interface CurrencyRateItem {
	"pr210": number;
	"pr_242": string;
	"pr_295": null;
}

export interface BonusItem {
	"MONTH": number; // or null
	"MonthBonus": number; // or null
}


export interface TransactionItem {
	"ID": number;
	"Class_id": number;
	"NOrder": number;
	"DT_Doc_UTC": string;
	"pr250": string; // 2017-04-24T13:57:17
	"pr208_Name": string;
	"pr332_Name": string;
	"pr258": number;
	"pr260": number;
	"pr259": number;
	"pr_235": null;
	"Order_id": null;
	"pr333": number;
	"sKey": string; // shopping or cargos
}


// sm_project: {Order_id: 1468265,pr249: 0,pr251: 0,pr253: null,pr278: null,pr281: null,pr441: null,pr_235: "test",pr_290: "2016-08-05T16:27:28",pr_296: "PRU-10887",pr_321: "2016-08-05T16:27:28"}
export interface OrderItem {
	"Order_id": number;
	"State_id": number;
	"pr249": number;
	"pr251": number;
	"pr253": number;
	"pr253_name": string; // type
	"pr278": number;
	"pr281": number;
	"pr441": number;
	"pr_235": string;
	"pr_290": string;
	"pr_296": string;
	"pr_321": string;
	"content": Array<string>; // detail about order content
}
// sm_project_det: [{Order_id: 1880003, pr291: "CR-64240"}]
export interface OrderDetItem {
	"Order_id": number;
	"pr291": string;
}


export interface ParcelItem {
	"ID": number;
	"N": number; // parcel number
	"isEditable": number; // 0 or 1
	"Order_id": number;
	"pr329": string;
	"pr321": string;
	"pr322_Name": string;
	"pr281": number;
	"pr325": number;
	"pr249": number;
	"pr327_Name": null;
	"pr323": string;
	"pr275_Name": string;
	"pr276_Name": string;
	"pr201_Name": null;
	"st11113": null; // 2017-08-18
	"st11112": null; // 2017-08-18
	"st11116": null; // 2017-08-18
	"CustomerName": string;
	"pr291": string;
	"pr_537": string;
	"pr277_Name": string;
}
export interface ParcelsTotal {
	"QtyOnMonth": number;
	"TotalWEIGHT": null;
	"inWay": null;
	"Problem": null;
}

export interface EventsLogItem {
	"DT_UTC": string;
	"F": number;
	"Note": string;
	"T": string;
	"sKEY": string;
	"o": Array<any>;
	"OBJECT_ID": number;
}

export interface OfficesTimeItem {
	"ID": number;
	"pr201_Name": string;
	"pr204_Name": string;
	"pr_243": number;
}

export interface NotificationItem {
	DT_Reg_UTC: string; // "2019-08-16T08:34:52"
	Det_id: number;
	ID: number;
	Msg: string;
	Object_id: number;
	Type_id: number;
	isComplete: number; // 0 | 1 | 2
}

export interface BankCard {
	"TypeId": number;
	"CardId": string; // card_1Guezc...
	"BrandLdm": string; // MasterCard
	"BrandOriginal": string;
	"Last4": string;
	"ExpMonth": number;
	"ExpYear": number;
	"IsDefault": boolean;
}

export interface MetaData {
	"md_class": Array<Class>;
	"md_domain": Array<any>;
	"md_metaobject_label": Array<MetaObjectLabel>;
	"md_subclass": Array<Class>;
	"md_property": Array<any>;
	"md_property_cl_v": Array<any>;
	"md_sys_type": Array<any>;
	"md_property_det": Array<any>;
}

const metadata: MetaData = {
	"md_class": [],
	"md_domain": [],
	"md_metaobject_label": [],
	"md_subclass": [],
	"md_property": [],
	"md_property_cl_v": [],
	"md_sys_type": [],
	"md_property_det": []
}

export interface AppData {
	"sm_customer": Customer;
	"sm_parcels_itrade": Array<any>;
	"sm_package": Array<any>;
	"cm_notification_event": Array<NotificationItem>;
	"Cards": Array<BankCard>;

	"sm_currency_rate": Array<CurrencyRateItem>;
	"sm_move_money": Array<TransactionItem>;
	"sm_project": Array<OrderItem>;
	"sm_project_det": Array<OrderDetItem>;
	"sm_order_parcels": Array<ParcelItem>;
	"sm_order_parcels_total": ParcelsTotal;
	"sm_log": Array<EventsLogItem>;
	"sm_times": Array<OfficesTimeItem>;
	"sm_customer_bonus_list": Array<BonusItem>;
	'metadata': MetaData;
	'user': any;
	'objectsListData': Array<ObjectsList>;
}

// Observable reactive data object
export const appData: AppData = {
	"sm_customer": { 'AccessLevel': '0000' } as Customer,
	"sm_parcels_itrade": [],
	"sm_package": [],
	"cm_notification_event": [],
	"Cards": [], // savedBankCards

	"sm_currency_rate": [],
	"sm_move_money": [],
	"sm_project": [],
	"sm_project_det": [],
	"sm_order_parcels": [],
	"sm_order_parcels_total": {} as ParcelsTotal,
	"sm_log": [],
	"sm_times": [],
	"sm_customer_bonus_list": [],
	'metadata': metadata,
	'user': {},
	'objectsListData': []
}

export interface Class {
	"Class_id": number;
	"needCheckOut": boolean;
}

export interface MetaObjectLabel {
	"MetaObject_id": number;
	"Name": string;
}

export interface ObjectsList {
	'key': number; // classId
	'objectsList': Array<any>;
}

export interface Breadcrumbs {
	'title': string;
	'src': string;
}


// CountryList N1021100__1
export interface Country {
	'Object_id': number;
	'CalcName': string;
	'pr_587': string;
}
export const countryList: Country[] = [
	{ "Object_id": 284, "CalcName": "United States", "pr_587": "US" },
	{ "Object_id": 285, "CalcName": "Ukraine", "pr_587": "UA" },
	{ "Object_id": 286, "CalcName": "Hong Kong", "pr_587": "HK" },
	{ "Object_id": 287, "CalcName": "China", "pr_587": "CN" },
	{ "Object_id": 288, "CalcName": "Poland", "pr_587": "PL" },
	{ "Object_id": 294, "CalcName": "Turkey", "pr_587": "TR" }
]

export const openMessagesPopup: Array<number> = []

export default appData