import store from '@/store'
import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home/Home.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'Root',
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'add-buyout',
        name: 'AddBuyout',
        component: () => import(/* webpackChunkName: "add-buyout" */ "@/views/AddBuyout/AddBuyout.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'add-delivery',
        name: 'AddDelivery',
        component: () => import(/* webpackChunkName: "add-delivery" */ "@/views/AddDelivery/AddDelivery.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'finance',
        name: 'Finance',
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Finance.vue'),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'warehouses',
        name: 'Warehouses',
        component: () => import(/* webpackChunkName: "warehouses" */ '@/views/Warehouses/Warehouses.vue'),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'refferal',
        name: 'Refferal',
        component: () => import(/* webpackChunkName: "refferal" */ '@/views/Refferal/Index.vue'),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'onboarding',
        name: 'Onboarding',
        component: () => import(/* webpackChunkName: "onboarding" */ '@/views/Onboarding/Onboarding.vue'),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'tariffs',
        name: 'Tariffs',
        component: () => import(/* webpackChunkName: "tariffs" */ '@/views/Tariffsv2/Tariffs.vue'),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'shops',
        name: 'Shops',
        component: () => import(/* webpackChunkName: "shops" */ '@/views/Shops/ShopSuggest.vue'),
        meta: {
          layout: 'RootLayout',
          requiresAuth: true
        }
      },
      {
        path: 'converter',
        name: 'ConvertCurrency',
        component: () => import(/* webpackChunkName: "shops" */ '@/views/ConvertCurrency/ConvertCurrency.vue'),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'parcel/:parcelId',
        name: 'ParcelInfo',
        props: (route: Route) => ({ parcelId: route.params.parcelId }),
        component: () => import(/* webpackChunkName: "parcel-info" */ "@/views/ParcelInfo/Index.vue"),
      },
      {
        path: 'events',
        name: 'Events',
        component: () => import(/* webpackChunkName: "events" */ "@/views/Events/Events.vue"),
        meta: {
          layout: 'RootLayout'
        }
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile/Index.vue"),
      },
      {
        path: 'share-product',
        name: 'ShareProduct',
        component: () => import(/* webpackChunkName: "share-product" */ "@/views/ShareProduct/Index.vue"),
      },
      {
        path: 'address-list',
        name: "MyAddress",
        component: () => import(/* webpackChunkName: "address-list" */ "@/views/MyAddress/Index.vue")
      },
      {
        path: 'address-list/new',
        name: "MyAddressNew",
        component: () => import(/* webpackChunkName: "address-list-new" */ "@/views/MyAddress/MyAddressNew.vue")
      },
      {
        path: 'videoshop/:id?',
        name: "VideoShop",
        component: () => import(/* webpackChunkName: "video-shop" */ "@/views/VideoShop/VideoShop.vue")
      },
      {
        path: 'help',
        name: "Help",
        component: () => import(/* webpackChunkName: "video-shop" */ "@/views/Onboarding/Onboarding.vue")
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login/Index.vue"),
    meta: {
      isAuth: true
    }
  },
  {
    path: '/sign-up',
    name: 'SignUpPage',
    component: () => import(/* webpackChunkName: "sign-up" */ "@/views/SignUp/Index.vue"),
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '/',
        name: 'SignUpForm',
        component: () => import(/* webpackChunkName: "sign-up-form" */ "@/views/SignUp/SignUpForm.vue"),
      },
      {
        path: 'confirm',
        name: 'SignUpConfirm',
        component: () => import(/* webpackChunkName: "sign-up-confirm" */ "@/views/SignUp/SignUpConfirm.vue"),
        meta: {
          isMustFrom: 'SignUpForm',
          isMustFromBase: 'SignUpForm'
        },
      }
    ]
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordPage',
    component: () => import(/* webpackChunkName: "reset-password" */ "@/views/ResetPassword/Index.vue"),
    meta: {
      isAuth: true
    },
    children: [
      {
        path: '/',
        name: 'ResetPasswordForm',
        component: () => import(/* webpackChunkName: "reset-password-form" */ "@/views/ResetPassword/ResetPasswordForm.vue"),
      },
      {
        path: 'confirm',
        name: 'ResetPasswordConfirm',
        component: () => import(/* webpackChunkName: "reset-password-confirm" */ "@/views/ResetPassword/ResetPasswordConfirm.vue"),
        meta: {
          isMustFrom: 'ResetPasswordForm',
          isMustFromBase: 'ResetPasswordForm'
        },
      },
      {
        path: 'new',
        name: 'ResetPasswordNew',
        component: () => import(/* webpackChunkName: "reset-password-new" */ "@/views/ResetPassword/ResetPasswordNew.vue"),
        meta: {
          isMustFrom: 'ResetPasswordConfirm',
          isMustFromBase: 'ResetPasswordForm'
        },
      },
    ]
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log('tofrom scroll', to, from, savedPosition)
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const isAuthGuarded = to.matched.some(record => record.meta.requiresAuth);
  const isAuth = to.matched.some(record => record.meta.isAuth);
  const isFromGuarded = to.meta?.isMustFrom;
  if (isFromGuarded) {
    if (from.name !== to.meta?.isMustFrom) {
      router.push({ name: to.meta?.isMustFromBase })
    }
  }

  if (isAuthGuarded && !store.getters['user/isLoggedIn']) {
    router.push("/login")
  } else if (isAuth && store.getters['user/isLoggedIn']) {
    router.push({ name: "Root" })
  } else {
    next()
  }
})

export default router
