import { clearAllData, setAppState } from "@/services/data-mutator";
import {
  getArchiveLastUpdates,
  getArchiveUserData,
  getLastUpdates,
  getUserData,
  login,
  resetPasswordConfirm,
  resetPasswordNewPass,
  resetPasswordSMS,
  sendSignUp,
  sendSignUpConfirm,
  sendRefferal,
  toggleShareProducts,
} from "@/services/requests-list";
import { generateRefferalLink } from "@/utils/utils";

const getDefaultState = () => ({
  user: {},
  status: "",
  token: localStorage.getItem("auth-token") || "",
  signUpData: {
    username: "",
    phoneNumber: "",
    password: "",
  },
  refferalCode: window.localStorage.getItem("refferalCode") || "",
  refferalLink: window.localStorage.getItem("refferalLink") || "",
  resetPasswordPhone: "",
  resetPasswordCountryCode: "",
  resetPasswordCode: "",
  logList: [],
  moveMoneyList: [],
  currencyRates: [],
});

// initial state
const state = getDefaultState();

// getters
const getters = {
  user: (state: any) => state.user,
  userCountry: (state: any) => state.user.pr201,
  userCountrySiteUrl: (state: any) => {
    const lang = state.user.pr201;

    const values = {
      285: "https://easyget.com.ua",
      284: "https://easyget.ai",
      301: "https://easyget.ai",
      308: "https://easyget.ai",
    };

    return values[lang];
  },
  userAddressCountry: (state: any) => {
    const lang = state.user.pr201;

    const values = {
      301: "gbr",
      308: "can",
    };

    return values[lang];
  },
  userCountryPhone: (state: any) => {
    const lang = state.user.pr201;

    const values = {
      285: "+380800217980",
      284: "+13055048599",
      301: "+13055048599",
      308: "+13055048599",
    };

    return values[lang];
  },
  userCountryCode: (state: any) => {
    const codes = {
      284: "USA",
      285: "UA",
      301: "GB",
      308: "CA",
    };

    return codes[state.user.pr201] || "UA";
  },
  userId: (state: any) => state.user.ID,
  isLoggedIn: (state: any) => !!state.token,
  userToken: (state: any) => state.token,
  authStatus: (state: any) => state.status,
  resetPasswordPhone: (state: any) => state.resetPasswordPhone,
  lastVersion: (state: any) => state.user.AplAppVersion,
  logList: (state: any) => state.logList,
  moveMoneyList: (state: any) => state.moveMoneyList,
  currencyRates: (state: any) => state.currencyRates,
  refferalLink: (state: any) => state.refferalLink,
  isAgreeShare: (state: any) => Boolean(state.user.pr830),
  sharedGoods: (state: any) => state.user.QtySharedGoods || 0,
};

// actions
const actions = {
  async loadUserData({ commit, state, dispatch }: any) {
    const res: any = await getUserData().catch((res: any) => res);
    if (res.sm_customer[0]) {
      commit("setUser", res.sm_customer[0]);
      if (!state.refferalLink)
        generateRefferalLink(
          res.sm_customer[0].pr_625,
          res.sm_customer[0].pr201,
          (code: string) => commit("setRefferalLink", code)
        );
    }
    if (res.sm_currency_rate) commit("setCurrencyRates", res.sm_currency_rate);
    if (res.sm_move_money) commit("setMoveMoneyList", res.sm_move_money);

    if (state.refferalCode) dispatch("activateRefferal");
  },
  async loadLastUpdates({ commit }: any) {
    const res: any = await getLastUpdates().catch((res: any) => res);
    if (res.sm_log) commit("setLogList", res.sm_log);
  },
  async loadArchiveLastUpdates({ commit }: any) {
    const res: any = await getArchiveLastUpdates().catch((res: any) => res);
    if (res.sm_log) commit("updateLogList", res.sm_log);
    console.log("archive updates", res.sm_log);
  },
  async loadArchiveData({ commit }: any) {
    const res: any = await getArchiveUserData().catch((res: any) => res);
    if (res.sm_move_money) commit("setMoveMoneyList", res.sm_move_money);
    console.log("loadArchiveData", res);
  },
  async loginUser({ commit }: any, { username, password }: any) {
    const res: any = await login({ username, password });
    console.log("login data", res);
    if (res.access_token) {
      localStorage.setItem("auth-token", res.access_token);
      commit("authSuccess", { token: res.access_token });
    }
  },
  async signUp(
    { commit }: any,
    { username, phoneNumber, password, countryCode }: any
  ) {
    const res: any = await sendSignUp(
      username,
      phoneNumber,
      password,
      countryCode
    );
    commit("setSignUpData", { username, phoneNumber, password, countryCode });
    console.log("signUp res", res);
  },
  async repeatSignUp(ctx: any) {
    const {
      username,
      phoneNumber,
      password,
      countryCode,
    } = ctx.state.signUpData;
    const res: any = await sendSignUp(
      username,
      phoneNumber,
      password,
      countryCode
    );
    console.log("resendSMS res", res);
  },
  async confirmSignUp(
    { commit, state }: any,
    data: { confirmCode: string; countryCode: number }
  ) {
    const res: any = await sendSignUpConfirm(
      state.signUpData.phoneNumber,
      data.confirmCode,
      state.signUpData.countryCode
    );
    if (res.access_token) {
      localStorage.setItem("auth-token", res.access_token);
      commit("authSuccess", { token: res.access_token });
    }
  },
  async activateRefferal({ commit, state, dispatch }: any) {
    await sendRefferal(state.user.ID, state.refferalCode).catch(
      (res: any) => res
    );
    commit("resetRefferalCode");
    await dispatch("loadLastUpdates");
  },
  async resetGetSMS(
    { commit }: any,
    data: { phoneNumber: string; countryCode: number }
  ) {
    const { phoneNumber, countryCode } = data;
    const res: any = await resetPasswordSMS(phoneNumber, countryCode);
    commit("setResetPasswordPhone", { phoneNumber, countryCode });
    console.log("resetGetSMS", res);
  },
  async resetConfirm({ commit, state }: any, confirmCode: any) {
    const res: any = await resetPasswordConfirm(
      state.resetPasswordPhone,
      confirmCode,
      state.resetPasswordCountryCode
    );
    commit("setResetPasswordCode", confirmCode);
    console.log("resetConfirm", res);
  },
  async resetNewPass({ commit, state }: any, newPassword: any) {
    const res: any = await resetPasswordNewPass(
      state.resetPasswordPhone,
      state.resetPasswordCode,
      newPassword,
      state.resetPasswordCountryCode
    );
    if (res.access_token) {
      localStorage.setItem("auth-token", res.access_token);
      commit("authSuccess", { token: res.access_token });
      commit("clearResetPasswordData");
    }
    console.log("resetNewPass", res);
  },
  async toggleIsShare({ commit, state }: any, newValue: boolean) {
    await toggleShareProducts(state.user.ID, newValue);
    commit("setShareStatus", newValue ? 1 : 0);
  },
  logout({ commit }: any) {
    commit("logout");
    localStorage.removeItem("auth-token");
    commit("parcels/resetState", null, { root: true });
    commit("address/resetState", null, { root: true });
    setAppState("isUserAuth", false);
    setAppState("isBaseDataLoading", false);
    setAppState("isParcelsDataLoaded", false);
    // setRoute("home");
    setAppState("isSidebarMobileOpen", false);
    setAppState("isScrollLocked", false);
    clearAllData();
  },
};

// mutations
const mutations = {
  setUser(state: any, data: any) {
    state.user = data;
  },
  authRequest(state: any) {
    state.status = "loading";
  },
  authSuccess(state: any, { token }: any) {
    state.status = "success";
    state.token = token;
  },
  authError(state: any) {
    state.status = "error";
  },
  logout(state: any) {
    state.status = "";
    state.token = "";
    state.user = {};
  },
  setSignUpData(state: any, payload: any) {
    state.signUpData = payload;
  },
  clearSignUpData(state: any) {
    state.signUpData = {
      username: "",
      phoneNumber: "",
      password: "",
    };
  },
  setResetPasswordPhone(state: any, payload: any) {
    const { phoneNumber, countryCode } = payload;
    state.resetPasswordPhone = phoneNumber;
    state.resetPasswordCountryCode = countryCode;
  },
  setResetPasswordCode(state: any, payload: any) {
    state.resetPasswordCode = payload;
  },
  clearResetPasswordData(state: any) {
    state.resetPasswordPhone = "";
    state.resetPasswordCode = "";
    state.resetPasswordCountryCode = "";
  },
  resetState(state: any) {
    console.log("reset user");
    Object.assign(state, getDefaultState());
  },
  setLogList(state: any, payload: any) {
    console.log("setLogs", payload);
    if (payload) state.logList = payload;
  },
  setRefferalLink(state: any, payload: any) {
    state.refferalLink = payload;
  },
  updateLogList(state: any, payload: any) {
    state.logList = [...state.logList, ...payload];
  },
  setMoveMoneyList(state: any, payload: any) {
    state.moveMoneyList = payload;
  },
  setCurrencyRates: (state: any, payload: any) => {
    state.currencyRates = payload;
  },
  setRefferalCode: (state: any, code: string) => {
    state.refferalCode = code;
    window.localStorage.setItem("refferalCode", code);
  },
  resetRefferalCode(state: any) {
    state.refferalCode = "";
    window.localStorage.setItem("refferalCode", "");
  },
  setShareStatus(state: any, value: boolean) {
    state.user.pr830 = value;
  },
  addSharedCount(state: any) {
    state.user.QtySharedGoods = state.user.QtySharedGoods + 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
