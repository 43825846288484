export enum Locales {
  RU = "ru-RU",
  UA = "uk-UA",
  US = "en-US",
}

export const LOCALES = [
  { value: Locales.UA, caption: "Українська", short: "Укр" },
  { value: Locales.RU, caption: "Русский", short: "Рус" },
  { value: Locales.US, caption: "English", short: "Eng" },
];
const currLang =
  LOCALES.find(
    (langItm) => langItm.value === window.localStorage.getItem("currLang")
  ) || LOCALES[0];

export const defaultLocale = currLang.value;
