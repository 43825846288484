import { FacebookEvents, FacebookParams } from "./fb-events";
import { getFirebaseDynamicLink } from "../services/api-connector";
import i18n from "@/i18n";

export function getMediaLink(link: string | null | undefined): string {
  if (!link) return "";
  return `${process.env.VUE_APP_API_URL}/media/${link}`;
}

export function getBaseOfLink(link: string): string {
  if (!link) return "";
  const pathArray = link.split("/");
  return pathArray[0] + "//" + pathArray[2];
}

// Sort array of objects by strings locale. Ascendant sort. Return sorted copy
export function sortedArObjsStr(
  arObj: Array<any>,
  key: string,
  ascendant = true
): Array<any> {
  if (!arObj.length) return [];
  return arObj.slice().sort((a: any, b: any) => {
    const nameA = a[key].toLowerCase() || "";
    const nameB = b[key].toLowerCase() || "";
    if (ascendant) return nameA.localeCompare(nameB);
    else return nameB.localeCompare(nameA);
  });
}
// Sort by int. Descendant (from max to min). Return sorted copy
export function sortedArObjsInt(
  arObj: Array<any>,
  key: string,
  descendant = true
): Array<any> {
  if (!arObj.length) return [];
  return arObj.slice().sort((a: any, b: any) => {
    if (descendant)
      // descendant sorting
      return b[key] - a[key];
    // ascendant
    else return a[key] - b[key];
  });
}

// Sort array of objects by date. Return sorted copy
export function sortedArObjsDate(
  arObj: Array<any>,
  key: string,
  descendant = true
): Array<any> {
  if (!arObj.length) return [];
  let dateAMS = 0;
  let dateBMS = 0;
  return arObj.slice().sort((a: any, b: any) => {
    dateAMS = a[key] ? new Date(a[key]).getTime() : 0;
    dateBMS = b[key] ? new Date(b[key]).getTime() : 0;
    if (descendant)
      // descendant sorting
      return dateBMS - dateAMS;
    // ascendant
    else return dateAMS - dateBMS;
  });
}

export function getObjInAr(
  arOfObj: Array<any>,
  key: string,
  val: any,
  isErrorLog = true
): any {
  let curItem: any = null;
  for (let i = 0; i < arOfObj.length; i++) {
    curItem = arOfObj[i];
    if (curItem[key] === val) return curItem;
  }
  if (isErrorLog)
    console.log(
      "Warn! Can not find object in array. Key: " + key + " Val: " + val
    );
  return null;
}

export function getObjIdxInAr(arOfObj: Array<any>, key: string, val: any): any {
  let curItem: any = null;
  for (let i = 0; i < arOfObj.length; i++) {
    curItem = arOfObj[i];
    if (curItem[key] === val) return i;
  }
  return -1;
}

//check is valid format file from src
export function isValidFormat(src: any, formats: Array<string>): boolean {
  if (src && src.indexOf(".") != -1) {
    const format = src.split(".").pop();
    if (formats.indexOf(format) != -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

// Firebase analytics
// logEventFA('select_content', {page_id: 'main'})
export function logEventFA(type: string, paramsObj?: any) {
  //@ts-ignore
  console.log("LOG EVENT FIREBASE", type, paramsObj);
  if (document.body.classList.contains("web")) {
    (window as any).firebaseLogger.logEvent(type, paramsObj);
  } else if ((window as any).cordova) {
    (window as any).cordova.plugins.firebase.analytics &&
      (window as any).cordova.plugins.firebase.analytics.logEvent(
        type,
        paramsObj
      );
  }
}

// Facebbok analytics
// logEventFB('select_content', {page_id: 'main'})
export function logEventFB(type: string, paramsObj?: any, valueToSum?: any) {
  //@ts-ignore
  const formattedType = FacebookEvents[type] ? FacebookEvents[type] : type;
  const transformParams = {};
  //@ts-ignore
  paramsObj &&
    Object.keys(paramsObj).forEach(
      (p: string) => (transformParams[FacebookParams[p]] = paramsObj[p])
    );
  console.log(
    "LOG EVENT FACEBOOK",
    type,
    "paramsObj",
    paramsObj,
    "valueToSum",
    valueToSum,
    "formattedType",
    formattedType,
    "transformParams",
    transformParams
  );
  if ((window as any).facebookConnectPlugin) {
    paramsObj
      ? (window as any).facebookConnectPlugin.logEvent(
          formattedType,
          transformParams,
          valueToSum
        )
      : (window as any).facebookConnectPlugin.logEvent(formattedType);
  }
  // (window as any).facebookConnectPlugin && (window as any).facebookConnectPlugin.logEvent(formattedType, paramsObj, valueToSum);
  // (window as any).facebookConnectPlugin && (window as any).facebookConnectPlugin.logEvent(type,
  //     paramsObj ? JSON.stringify(paramsObj) : '',
  //     valueToSum ? valueToSum : 0);
  // let platform = 'android' // check platfoem locally. utils should not have acces to store for determine platform
  // if (document.body.classList.contains('ios')) platform = 'ios'
  // if (window['cordova']) { // only for android
  // window['cordova'].exec(
  //     (token: string)=>{ }, // success
  //     (err: any)=>{ console.error('Error log Facebook event') },
  //     "facebookConnectPlugin",
  //     "logEvent",
  //     [
  //         type,
  //         paramsObj ? JSON.stringify( paramsObj ) : '',
  //         valueToSum ? valueToSum : 0
  //     ]
  // )
  // } else console.warn('Cordova does not exist (cordova.exec)')
}

export interface Order {
  name: string | null | undefined;
  source?: string | null | undefined;
  linkList?: any[];
  photo?: string | null | undefined;
  count: number;
  country: number | null;
  size?: string | null;
  color?: string | null;
  model?: string | null;
  customerId?: string | number;
  userCountry?: number;
  isLink?: boolean;
  type?: number | null;
  mainImg?: string | null;
}
export function getOrderRequestData(data: Order): any {
  //basic
  const requestData: any = {
    tmp_object_list: [
      {
        OBJECT_ID: -1,
        Class_id: 24640,
        ObjectDB_id: null,
      },
    ],
    tmp_object_property_scalar: [
      { OBJECT_ID: -1, Property_id: 475, VALUE: data.customerId },
      { OBJECT_ID: -1, Property_id: 520, VALUE: data.type, Oper: "I" },
      { OBJECT_ID: -1, Property_id: 474, VALUE: data.name },
      { OBJECT_ID: -1, Property_id: 256, VALUE: data.count },
      { OBJECT_ID: -1, Property_id: 275, VALUE: data.country },
      { OBJECT_ID: -1, Property_id: 276, VALUE: data.userCountry },
    ],
  };

  if (data.type === 11691) {
    requestData["tmp_object_property_scalar"].push({
      OBJECT_ID: -1,
      Property_id: -600,
      VALUE: data.mainImg ? data.mainImg : -600, // or empty
    });
  } else if (data.mainImg) {
    requestData["tmp_object_property_scalar"].push({
      OBJECT_ID: -1,
      Property_id: -600,
      VALUE: data.mainImg,
    });
  }

  if (data.isLink) {
    requestData["tmp_object_property_scalar"].push({
      OBJECT_ID: -1,
      Property_id: 451,
      VALUE: data.source,
      Oper: "I",
    });
  }

  //optional
  if (data.model)
    requestData["tmp_object_property_scalar"].push({
      OBJECT_ID: -1,
      Property_id: 444,
      VALUE: data.model,
      Oper: data.model,
    });
  if (data.size)
    requestData["tmp_object_property_scalar"].push({
      OBJECT_ID: -1,
      Property_id: 446,
      VALUE: data.size,
      Oper: "I",
    });
  if (data.color)
    requestData["tmp_object_property_scalar"].push({
      OBJECT_ID: -1,
      Property_id: 445,
      VALUE: data.color,
      Oper: "I",
    });

  return requestData;
}

export function isURL(str: string | null | undefined): boolean {
  if (!str) return false;
  //eslint-disable-next-line
  const pattern = /(ftp|http|https):\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return !!pattern.test(str);
}

export function getCountryName(id: number | null): string {
  switch (id) {
    case 287:
      return "China";
    case 284:
      return "USA";
    default:
      return "";
  }
}

export interface ShopGroup {
  id: number;
  name: object;
  order: number;
  sellerList: Array<ShopSeller>;
}

export interface ShopSeller {
  id: number;
  name: string;
  description: object;
  image: string;
  link: string;
  country: number;
  groupId: number;
  order: number;
}
export interface ShopProduct {
  id: number;
  name: object;
  description: object;
  imgList: string[];
  images: string[];
  link: string;
  seller: ShopSeller;
  deliveryTime: Array<number>;
  deliveryPrice: number;
  price: number;
  oldPrice: number;
  order: number;
}

export function getTransformedShops(data: any) {
  const sellerList: ShopSeller[] = data.N1023970__1.map((item: any) => ({
    id: item.Object_id,
    name: item.pr_222,
    description: {
      ["uk-UA"]: item.pr_271,
      ["ru-RU"]: item.pr_235,
      ["en-US"]: item.pr_235,
    },
    image: getMediaLink(item.pr_600),
    link: item.pr_472,
    baseLink: getBaseOfLink(item.pr_472),
    country: item.pr201,
    groupId: item.pr510,
    order: item.pr_262,
  })).sort((a: any, b: any) => a.order - b.order);

  const sellerObj = sellerList.reduce((acc, cur) => {
    //@ts-ignore
    acc[cur.id] = cur;
    return acc;
  });

  const productImagesObj = data.N3023330__599.map((el: any) => ({
    id: el.Object_id,
    src: el.pr_600,
  })).reduce((acc: any, cur: { id: number; src: string }) => {
    acc[cur.id] ? acc[cur.id].push(cur.src) : (acc[cur.id] = [cur.src]);
    return acc;
  }, {});

  const productList: ShopProduct[] = data.N1023330__1.map((item: any) => {
    const imgList = [item.pr_600 ? item.pr_600 : null]
      .concat(
        productImagesObj[item.Object_id] ? productImagesObj[item.Object_id] : []
      )
      .filter(Boolean);
    const images = imgList.map((l: string) => getMediaLink(l));
    return {
      id: item.Object_id,
      name: {
        ["uk-UA"]: item.pr_270,
        ["ru-RU"]: item.pr_222,
        ["en-US"]: item.pr214,
      },
      description: {
        ["uk-UA"]: item.pr_271,
        ["ru-RU"]: item.pr_235,
        ["en-US"]: item.pr_235,
      },
      imgList,
      images,
      pr600: item.pr_600,
      link: item.pr_472,
      baseLink: getBaseOfLink(item.pr_472),
      //@ts-ignore
      seller: sellerObj[item.pr659] || {},
      deliveryTime: [item.pr620, item.pr621],
      deliveryPrice: item.pr660,
      price: item.pr249,
      oldPrice: item.pr661,
      order: item.pr_262,
      platform: item.pr800,
    };
  }).sort((a: any, b: any) => a.order - b.order);

  const groupList: ShopGroup[] = data.N1023960__1.map((item: any) => ({
    id: item.Object_id,
    name: {
      ["uk-UA"]: item.pr_270,
      ["ru-RU"]: item.pr_222,
      ["en-US"]: item.CalcName,
    },
    order: item.pr_262,
    platform: item.pr800,
    sellerList: [
      ...sellerList
        .filter((p) => p.groupId === item.Object_id)
        .sort((a: any, b: any) => a.order - b.order),
    ],
  })).sort((a: any, b: any) => a.order - b.order);

  return {
    groupList,
    productList,
  };
}

export function findItemById(list: Array<any>, id: number) {
  return list.find((itm: any) => +itm.id === +id) || {};
}

export function openExternal(url: string) {
  //@ts-ignore
  const device = window["device"];
  if (!device) {
    window.open(url, "_blank");
    return false;
  }

  if (device.platform.toUpperCase() === "ANDROID") {
    //@ts-ignore
    navigator.app.loadUrl(url, { openExternal: true });
  }
  if (device.platform.toUpperCase() === "IOS") {
    //@ts-ignore
    cordova.InAppBrowser.open(url, "_system");
  }
}

export function openStore() {
  //@ts-ignore
  const device = window["device"];
  if (!device) {
    return false;
  }

  if (device.platform.toUpperCase() === "ANDROID") {
    //@ts-ignore
    navigator.app.loadUrl(
      "https://play.google.com/store/apps/details?id=com.utec.easyget",
      { openExternal: true }
    );
  }
  if (device.platform.toUpperCase() === "IOS") {
    //@ts-ignore
    cordova.InAppBrowser.open(
      "https://apps.apple.com/app/apple-store/id1473829534?pt=117926046",
      "_system"
    );
  }
}

export function getParameterByName(name: string, url: string): string {
  if (!url) return "";
  //eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function vibrate(time: number) {
  if (navigator.vibrate) {
    navigator.vibrate(time);
  }
}

export interface DynamicData {
  orderId: number;
  refferalCode: string;
  countryCode: number;
}
export function generateDynamicLink(
  { orderId, refferalCode, countryCode }: DynamicData,
  onSuccess: (url: string | undefined) => void
) {
  const image = {
    285: "https://i.ibb.co/k1PVxDh/677.png",
    284: "https://i.ibb.co/YQPH0jQ/image.jpg",
  };
  const options = {
    domainUriPrefix: "https://easyget.page.link",
    // link: `https://app.easyget.com.ua/?product=${orderId}&refferal=${refferalCode}`,
    link: `https://easyget.ai/?product=${orderId}&refferal=${refferalCode}#about-easyget`,
    androidInfo: {
      androidPackageName: "com.utec.easyget",
    },
    iosInfo: {
      iosBundleId: "com.easyget.easyget",
      iosAppStoreId: "1473829534",
    },
    analyticsInfo: {
      googlePlayAnalytics: {
        utmSource: "product",
        utmMedium: "referral",
      },
    },
    socialMetaTagInfo: {
      socialTitle: i18n.t("refferalShareTitle"),
      socialDescription: i18n.t("refferalShareDescription"),
      socialImageLink: image[countryCode],
    },
  };
  //@ts-ignore
  if (window["cordova"] && window["cordova"].plugins.firebase) {
    //@ts-ignore
    const fb = window["cordova"].plugins.firebase;
    fb.dynamiclinks
      .createShortDynamicLink({ ...options })
      .then(function(url: any) {
        console.log("Dynamic link was created:", url);
        onSuccess(url);
      });
  } else {
    getFirebaseDynamicLink(
      {
        dynamicLinkInfo: {
          ...options,
        },
        suffix: {
          option: "SHORT",
        },
      },
      ({ shortLink }: any) => {
        console.log("dynamicLink data", shortLink);
        onSuccess(shortLink);
      },
      () => console.log("Dynamic link error!")
    );
  }
}

export function copyToClipboard(text: string, onSuccess: () => void) {
  const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      onSuccess();
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function() {
      onSuccess();
    },
    function(err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

export function generateRefferalLink(
  refferalCode: number,
  countryCode: number,
  callback = (code: string) => code
) {
  const image = {
    285: "https://i.ibb.co/9Vv3sXT/Catcat.png",
    284: "https://i.ibb.co/sKngMKX/ref15.jpg",
  };
  // eng https://eg-img.s3.fr-par.scw.cloud/refCat/ref15.jpg
  // ua https://i.ibb.co/9Vv3sXT/Catcat.png

  const socialImageLink = image[countryCode] || image[285];
  const options = {
    domainUriPrefix: "https://easyget.page.link",
    link: `https://easyget.ai/?refferal=${refferalCode}#about-easyget`,
    androidInfo: {
      androidPackageName: "com.utec.easyget",
    },
    iosInfo: {
      iosBundleId: "com.easyget.easyget",
      iosAppStoreId: "1473829534",
    },
    analyticsInfo: {
      googlePlayAnalytics: {
        utmSource: "app",
        utmMedium: "referral",
      },
    },
    socialMetaTagInfo: {
      socialTitle: i18n.t("refferalShareTitle"),
      socialDescription: i18n.t("refferalShareDescription"),
      socialImageLink,
    },
  };
  //@ts-ignore
  if (window["cordova"]) {
    document.addEventListener("deviceready", () => {
      //@ts-ignore
      window["cordova"].plugins.firebase.dynamiclinks
        .createShortDynamicLink({ ...options })
        .then(function(url: any) {
          window.localStorage.setItem("refferalLink", url);
          callback(url);
        });
    });
  } else {
    getFirebaseDynamicLink(
      {
        dynamicLinkInfo: {
          ...options,
        },
        suffix: {
          option: "SHORT",
        },
      },
      ({ shortLink }: any) => {
        window.localStorage.setItem("refferalLink", shortLink);
        callback(shortLink);
      },
      () => console.log("Dynamic link error!")
    );
  }
}

export function generateShopDynamicLink(
  { sharedShopId, imageLink }: any,
  onSuccess: (url: string) => void
) {
  const options = {
    domainUriPrefix: "https://easyget.page.link",
    // link: `https://app.easyget.com.ua/?sharedShopId=${sharedShopId}`,
    link: `https://easyget.ai/?sharedShopId=${sharedShopId}#about-easyget`,
    androidInfo: {
      androidPackageName: "com.utec.easyget",
    },
    iosInfo: {
      iosBundleId: "com.easyget.easyget",
      iosAppStoreId: "1473829534",
    },
    analyticsInfo: {
      googlePlayAnalytics: {
        utmSource: "shop",
        utmMedium: "sharing",
      },
    },
    socialMetaTagInfo: {
      socialTitle: i18n.t("refferalShareTitle"),
      socialDescription: i18n.t("refferalShareDescription"),
      socialImageLink: imageLink,
    },
  };
  //@ts-ignore
  if (window["cordova"] && window["cordova"].plugins.firebase) {
    //@ts-ignore
    const fb = window["cordova"].plugins.firebase;
    fb.dynamiclinks
      .createShortDynamicLink({ ...options })
      .then(function(url: any) {
        console.log("Dynamic link was created:", url);
        onSuccess(url);
      });
  } else {
    getFirebaseDynamicLink(
      {
        dynamicLinkInfo: {
          ...options,
        },
        suffix: {
          option: "SHORT",
        },
      },
      ({ shortLink }: any) => {
        onSuccess(shortLink);
      },
      () => console.log("Dynamic link error!")
    );
  }
}

export function convertImgToDataURLviaCanvas(
  url: string,
  callback: (data: any) => void
) {
  if (document.body.classList.contains("ios")) {
    callback(url);
    return false;
  }
  const img: any = new Image();

  img.crossOrigin = "Anonymous";

  img.onload = function() {
    let canvas: any = document.createElement("CANVAS");
    const ctx = canvas.getContext("2d");
    canvas.height = this.height;
    canvas.width = this.width;
    ctx.drawImage(this, 0, 0);
    const dataURL = canvas.toDataURL();
    callback(dataURL);
    canvas = null;
  };

  img.src = url;
}

export interface DeliveryItem {
  name?: string;
  price?: number;
  count?: number;
}
export function getDeliveryRequestData(
  trackingNumber: string | null,
  activeWarehouse: any,
  productList: DeliveryItem[],
  userId: any,
  userCountry: number
): any {
  const productData = [] as Array<{}>;
  productList.forEach((product: any, idx) => {
    const totalPrice = product.count * product.price;
    productData.push(
      {
        OBJECT_ID: -1,
        Row_id: idx + 1,
        Property_Master_id: 477,
        Property_id: 474,
        VALUE: product.name,
        Oper: "I",
      }, // description
      {
        OBJECT_ID: -1,
        Row_id: idx + 1,
        Property_Master_id: 477,
        Property_id: 249,
        VALUE: totalPrice,
        Oper: "I",
      }, // price
      {
        OBJECT_ID: -1,
        Row_id: idx + 1,
        Property_Master_id: 477,
        Property_id: 256,
        VALUE: product.count,
        Oper: "I",
      }
    );
  });
  const requestData: any = {
    tmp_object_list: [{ OBJECT_ID: -1, Class_id: 24800, ObjectDB_id: null }],
    tmp_object_property_scalar: [
      {
        OBJECT_ID: -1,
        Property_id: -296,
        VALUE: trackingNumber,
        Oper: "I",
      }, // track
      {
        OBJECT_ID: -1,
        Property_id: 299,
        VALUE: trackingNumber,
        Oper: "I",
      }, // local track
      {
        OBJECT_ID: -1,
        Property_id: 476,
        VALUE: activeWarehouse.Object_id,
        Oper: "I",
      }, // warehouse
      { OBJECT_ID: -1, Property_id: -244, VALUE: 178, Oper: "I" }, // currency USD
      {
        OBJECT_ID: -1,
        Property_id: 475,
        VALUE: userId,
        Oper: "I",
      }, // customer id
    ],
    tmp_object_property_det: productData,
    pr275: activeWarehouse.pr201,
    pr276: userCountry,
  };
  return requestData;
}

export async function getNameFromClipboard(callback: (data: string) => void) {
  //@ts-ignore
  const clipBoard = window["cordova"] && window["cordova"].plugins.clipboard;
  const setLink = (data: string) => {
    if (isURL(data)) {
      callback(data);
    }
  };
  if (clipBoard) {
    clipBoard.paste(setLink);
  } else {
    const text = await navigator.clipboard.readText();
    setLink(text);
  }
}

export function getNumEnding(iNumber: number, aEndings: string[]): string {
  let sEnding, i;
  iNumber = iNumber % 100;
  if (iNumber >= 11 && iNumber <= 19) {
    sEnding = aEndings[2];
  } else {
    i = iNumber % 10;
    switch (i) {
      case 1:
        sEnding = aEndings[0];
        break;
      case 2:
      case 3:
      case 4:
        sEnding = aEndings[1];
        break;
      default:
        sEnding = aEndings[2];
    }
  }
  return `${iNumber} ${sEnding}`;
}

export function startsWith(str: string, word: string) {
  return str.lastIndexOf(word, 0) === 0;
}

export function getParcelRequestData(id: number) {
  const data = {
    ClassList: [{ Class_id: 24800, TypeLoad: 1 }],
    PropertyList: [
      { Class_id: 24800, Property_id: 299 }, // track
      { Class_id: 24800, Property_id: 249 }, // price
      { Class_id: 24800, Property_id: 399 }, // local delivery
      { Class_id: 24800, Property_id: 325 }, // fact weight or 281 weight
      { Class_id: 24800, Property_id: 476 }, // warehouse
      { Class_id: 24800, Property_id: 491 }, // arrive date
      { Class_id: 24800, Property_id: 477 }, // content
      { Class_id: 24800, Property_id: 489 }, //  Ссылка на список адресов клиента [Целое 32]
      { Class_id: 24800, Property_id: 595 }, //  страховка [Логическое] is11521
      { Class_id: 24800, Property_id: -601 }, // photoExist
    ],
    PropertyDetailList: [
      { Class_id: 24800, PropertyMaster_id: 477, Property_id: -309 }, // id
      { Class_id: 24800, PropertyMaster_id: 477, Property_id: 249 }, // price
      { Class_id: 24800, PropertyMaster_id: 477, Property_id: 256 }, // qty
      { Class_id: 24800, PropertyMaster_id: 477, Property_id: 474 }, //
    ],
    Filter: [
      // {"Class_id":24800, "Filter_id":1, "Node_id":1, "Property_id":252, "PropertyDetail_id":268, "Operator_id":"in", "Value": "(11084,11214,11087)"},
      // {"Class_id":24800, "Filter_id":2, "Node_id":1, "Property_id":-290, "Operator_id":">=", "Value": dPast10.toJSON()} // '01.03.2018'
      {
        Class_id: 24800,
        Filter_id: 1,
        Node_id: 1,
        Property_id: -1,
        Operator_id: "=",
        Value: id,
      },
    ],
  };

  return data;
}

export function removeEmpty(obj: {}) {
  Object.entries(obj).forEach(
    ([key, val]) =>
      (val && typeof val === "object" && removeEmpty(val)) ||
      //@ts-ignore
      ((val === null || val === "") && delete obj[key])
  );
  return obj;
}

export function urlify(text: string) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '">' + url + "</a>";
  });
}

export function getDeviceData() {
  const { offsetWidth: dpi } = document.getElementById("dpihelper") as any;
  const deviceData = {
    screenSize: "",
    dpi,
  };
  if (screen.width) {
    const width = screen.width ? screen.width : "";
    const height = screen.height ? screen.height : "";
    deviceData.screenSize += "" + width + " x " + height;
  }
  return deviceData;
}

export function initRatingApp() {
  return new Promise((resolve, reject) => {
    const launcher = (window as any).LaunchReview || {};
    if (!launcher) return reject(false);

    const appList = {
      ios: "1473829534",
      android: "com.utec.easyget",
    };
    const platform = (window as any).device?.platform?.toLowerCase();
    const isSupported = launcher.isRatingSupported();

    if (isSupported) {
      launcher.rating(resolve, reject);
    } else {
      launcher.launch(resolve, reject, appList[platform]);
    }
  });
}

export function moveCaretToEnd(el: HTMLInputElement) {
  if (typeof el.selectionStart == "number") {
    el.selectionStart = el.selectionEnd = el.value.length;
  }
}
