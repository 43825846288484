// Explicitly register all custom app events in one place
// Some components need to implement event handler (this.$root.$on('myEvent', (data)=>{...})

const eventsRegister = {
	'logOut': 'logOut',
	'toggleSidebar': 'toggleSidebar',
	'showPopup': 'showPopup',
	'closePopup': 'closePopup',
	'addMessage': 'addMessage',
	'clearMessages': 'clearMessages',
	'setAppState': 'setAppState',
	'showDialoq': 'showDialoq',
	'resetParcels': 'resetParcels',
	'reloadDefaultAddress': 'reloadDefaultAddress',
	'reSetLinkListeners': 'reSetLinkListeners',
	'updateUserData': 'updateUserData',
	'updateParcels': 'updateParcels',
	'updateAddresses': 'updateAddresses',
	'updateNotifications': 'updateNotifications',
	'updateLastUpdates': 'updateLastUpdates',
	'updateappdata': 'updateappdata',
	'balanceChanged': 'balanceChanged',
	'updateSavedCards': 'updateSavedCards',
}
export default eventsRegister
