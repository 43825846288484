import {
  deleteAddress,
  getAddress,
  getCarrierId,
  getCityList,
  getNewPostList,
  getStateList,
  getStreetList,
  newAddress,
  newAddressUSA,
  setDefaultAddress,
} from "@/services/requests-list";
import { findItemById } from "@/utils/utils";

const getDefaultState = () => ({
  addressList: [],
  stateList: [],
  cityList: [],
  streetList: [],
  postDataList: [],
  carrierId: 0,
});

// initial state
const state = getDefaultState();
// initial state

// getters
const getters = {
  addressList: (state: any) => state.addressList,
  stateList: (state: any) => state.stateList,
  cityList: (state: any) => state.cityList,
  streetList: (state: any) => state.streetList,
  postDataList: (state: any) => state.postDataList,
  defaultAddress: (state: any, getters: any) =>
    getters.addressList.find((el: any) => el.pr_298),
  carrierId: (state: any) => state.carrierId,
  stateById: (state: any, getters: any) => (id: number) =>
    findItemById(getters.stateList, id),
  cityById: (state: any, getters: any) => (id: number) =>
    findItemById(getters.cityList, id),
  streetById: (state: any, getters: any) => (id: number) =>
    findItemById(getters.streetList, id),
  postById: (state: any, getters: any) => (id: number) =>
    findItemById(getters.postDataList, id),
};

// actions
const actions = {
  async getAddressList({ commit }: any) {
    const res: any = await getAddress();
    commit("setAddressList", res.sm_address_list);
  },
  async setDefault({ commit, rootGetters }: any, id: number) {
    await setDefaultAddress(id, rootGetters["user/userId"]);
    commit("updateDefaultAddress", id);
  },
  async removeAddress({ commit, rootGetters }: any, id: number) {
    await deleteAddress(id, rootGetters["user/userId"]);
    commit("delete", id);
  },
  async createAddress({ rootGetters }: any, data: any) {
    await newAddress({ ...data, userId: rootGetters["user/userId"] });
  },
  async createAddressUSA({ rootGetters }: any, data: any) {
    await newAddressUSA({ ...data, userId: rootGetters["user/userId"] });
  },
  async loadStateList({ commit, rootGetters }: any) {
    const res: any = await getStateList(rootGetters["user/user"].pr201);
    if (res["N1021200__1"]) {
      commit(
        "updateStateList",
        res["N1021200__1"].map((itm: any) => ({
          name: itm.CalcName,
          id: itm.Object_id,
        }))
      );
    }
  },
  async loadCityList({ commit }: any, id: number) {
    const res: any = await getCityList(id);
    if (res["N1021400__1"]) {
      commit(
        "updateCityList",
        res["N1021400__1"].map((itm: any) => ({
          name: itm.CalcName,
          id: itm.Object_id,
        }))
      );
    }
  },
  async loadStreetList({ commit }: any, id: number) {
    const res: any = await getStreetList(id);
    if (res["N3021400_0205"]) {
      commit(
        "updateStreetList",
        res["N3021400_0205"].map((itm: any) => ({
          name: itm.pr_222,
          id: itm.pr_309,
        }))
      );
    }
  },
  async loadPostDepartments({ commit, rootGetters }: any, id: number) {
    let carrierId = rootGetters["address/carrierId"];
    if (!carrierId["address/carrierId"]) {
      const carrierData: any = await getCarrierId();
      carrierId = carrierData["N1022200__1"][0]["Object_id"];
      commit("setCarrierId", carrierData["N1022200__1"][0]["Object_id"]);
    }
    const postData: any = await getNewPostList(id, carrierId);
    if (postData["N1021510__1"]) {
      commit(
        "updatePostDataList",
        postData["N1021510__1"].map((itm: any) => ({
          name: itm.CalcName,
          id: itm.Object_id,
        }))
      );
    }
  },
};

// mutations
const mutations = {
  setAddressList(state: any, addressList: any) {
    state.addressList = addressList;
  },
  updateDefaultAddress(state: any, id: number) {
    state.addressList = state.addressList.map((adr: any) => ({
      ...adr,
      pr_298: +adr.ID === +id ? true : false,
    }));
  },
  delete(state: any, id: number) {
    state.addressList = state.addressList.filter((adr: any) => +adr.ID !== +id);
  },
  updateStateList(state: any, list: any) {
    state.stateList = list;
  },
  updateCityList(state: any, list: any) {
    state.cityList = list;
  },
  updateStreetList(state: any, list: any) {
    state.streetList = list;
  },
  updatePostDataList(state: any, list: any) {
    state.postDataList = list;
  },
  setCarrierId(state: any, id: any) {
    state.carrierId = id;
  },
  resetState(state: any) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
