











































































































/* eslint-disable */
import Vue from "vue";
import eventsRegister from "@/services/events-register";

interface Message {
  type: string;
  message: string;
}

let messageTimer: any = null;

export default Vue.extend({
  data() {
    return {
      type: "success",
      message: "",

      messShowTime: 2000, // ms
    };
  },

  created() {
    const self = this;
    // Emit event example:
    // this.$root.$emit(eventsRegister.addMessage, { type: 'success', message: 'Sample message' })
    this.$root.$on(eventsRegister.addMessage, (newMess: Message) => {
      self.type = newMess.type || "success";
      self.message = newMess.message;

      // Auto close by timer message
      clearTimeout(messageTimer);
      messageTimer = setTimeout(self.closeMessage, self.messShowTime);
    });
    this.$root.$on(eventsRegister.clearMessages, () => {
      this.message = "";
    });
  },

  methods: {
    closeMessage() {
      this.message = "";
      clearTimeout(messageTimer);
    },
  },
});
