import i18n from "@/i18n";
import { defaultLocale } from "@/locales/locales";
import { Locales } from "@/locales/locales";
import {
  gerRefferalsPrice,
  getCountryToRates,
  getSettings,
  getTarrifs,
  getUserDataByIP,
} from "../../services/requests-list";

interface PriceInfo {
  Coef_price: number;
  Perc_disc: number;
  Price_by_kg: number;
  kg_from: number;
  kg_to: number;
}

// initial state
const state = () => ({
  language: defaultLocale,
  appVersion: "2.30.170",
  userIPData: {
    phoneCode: window.localStorage.getItem("phoneCode") || "+380",
    countryCode: window.localStorage.getItem("countryCode") || "UA",
  },
  dutyFreeLimit: 168,
  freeTaxLimit: 112,
  customsService: 5,
  buyoutPercent: 7,
  combinePrice: 2,
  dividePrice: 2,
  photoPrice: 2,
  repackPrice: 2,
  consultPrice: 2,
  volumeCoff: 165,
  insurancePercent: 2.5,
  insuranceMinCost: 1,
  minBuyoutService: 2,
  isOnline: true,
  isConnectionError: false,
  isPopupActive: false,
  isAppGuideActive: window.localStorage.getItem("isAppGuideDisabled") || false,
  isCanMakePayments: false,
  deliveryRates: {
    USA: 7.9,
    CN: 11.9,
    TK: 0,
    UA: 16,
  },
  deliveryRatesGrid: {
    USA: [
      {
        from: 0,
        to: Infinity,
        price: 24,
      },
    ],
    CA: [
      {
        from: 0,
        to: Infinity,
        price: 20,
      },
    ],
    GB: [
      {
        from: 0,
        to: Infinity,
        price: 23,
      },
    ],
    UA: [
      {
        from: 0,
        to: Infinity,
        price: 16,
      },
    ],
  },
  refferalRates: {
    USA: 12,
    UA: 3.33,
  },
  helpData: {
    isSettingBtnActive: false,
    isPopupSlideActive: false,
  },
  minDeliveryTime: 7,
  maxDeliveryTime: 14,
  openMessages: [],
  pushNotification: {},
  sharedProductId: null,
  sharedShopId: null,
});

// getters
const getters = {
  appVersion: (state: any) => state.appVersion,
  lang: (state: any) => state.language,
  userLangSiteUrl: (state: any) => {
    const lang = state.language;

    const values = {
      "uk-UA": "https://easyget.com.ua",
      "ru-RU": "https://easyget.com.ua",
      "en-US": "https://easyget.ai",
    };

    return values[lang];
  },
  userLangCode: (state: any) => {
    const lang = state.language;
    const values = {
      "uk-UA": -38,
      "ru-RU": -37,
      "en-US": -39,
    };

    return values[lang];
  },
  refferalRate: (
    state: any,
    getters: any,
    rootState: any,
    rootGetters: any
  ) => {
    const userCoutry = rootGetters["user/userCountryCode"];
    return state.refferalRates[userCoutry];
  },
  deliveryTime: (state: any) =>
    `${state.minDeliveryTime}-${state.maxDeliveryTime}`,
  dutyFreeLimit: (state: any) => state.dutyFreeLimit,
  freeTaxLimit: (state: any) => state.freeTaxLimit,
  customsService: (state: any) => state.customsService,
  buyoutPercent: (state: any) => state.buyoutPercent,
  combinePrice: (state: any) => state.combinePrice,
  dividePrice: (state: any) => state.dividePrice,
  photoPrice: (state: any) => state.photoPrice,
  repackPrice: (state: any) => state.repackPrice,
  consultPrice: (state: any) => state.consultPrice,
  volumeCoff: (state: any) => state.volumeCoff,
  insurancePercent: (state: any) => state.insurancePercent,
  insuranceMinCost: (state: any) => state.insuranceMinCost,
  minBuyoutService: (state: any) => state.minBuyoutService,
  isOnline: (state: any) => state.isOnline,
  isConnectionError: (state: any) => state.isConnectionError,
  isPopupActive: (state: any) => state.isPopupActive,
  deliveryRates: (state: any) => state.deliveryRates,
  deliveryRatesGrid: (state: any) => state.deliveryRatesGrid,
  isSettingBtnActive: (state: any) => state.helpData.isSettingBtnActive,
  isPopupSlideActive: (state: any) => state.helpData.isPopupSlideActive,
  isAppGuideActive: (state: any) => state.isAppGuideActive,
  isCanMakePayments: (state: any) => state.isCanMakePayments,
  openMessages: (state: any) => state.openMessages,
  pushNotification: (state: any) => state.pushNotification,
  sharedProductId: (state: any) => state.sharedProductId,
  sharedShopId: (state: any) => state.sharedShopId,
  userIPData: (state: any) => state.userIPData,
};

// actions
const actions = {
  async getRates({ rootGetters, commit }: any) {
    const userCountry = rootGetters["user/userCountry"];
    const res = await getCountryToRates(userCountry).catch((res) => res);
    commit("setRates", res);
  },
  async getSettings({ commit }: any) {
    const res = await getSettings().catch((res) => res);
    commit("setSettings", res);
  },
  async getUserDataByIP({ commit }: any) {
    if (window.localStorage.getItem("phoneCode")) {
      return;
    }

    const data = await getUserDataByIP();

    if (data) {
      const allowedCountry = ["US", "UA", "CA", "GB"];

      if (data.countryCode === "ES") {
        window.localStorage.setItem("phoneCode", "+1");
        window.localStorage.setItem("countryCode", "GB");
        commit("setUserIPData", {
          phoneCode: "+1",
          countryCode: "GB",
        });
        commit("setLanguage", "en-US");
        return;
      }

      if (allowedCountry.includes(data.countryCode)) {
        window.localStorage.setItem("phoneCode", data.phoneCode);
        window.localStorage.setItem("countryCode", data.countryCode);
        commit("setUserIPData", data);

        if (["US", "CA", "GB"].includes(data.countryCode)) {
          commit("setLanguage", "en-US");
        }
      } else {
        window.localStorage.setItem("phoneCode", "+380");
        window.localStorage.setItem("countryCode", "UA");
      }
    }
  },
  async loadRatesData({ commit }: any) {
    // const [
    //   USA,
    //   CN,
    //   // TK
    // ] = await getTarrifsData();

    const [UA, USA, GB, Canada] = await getTarrifs();
    commit("setDeliveryRates", {
      USA: USA?.Price,
      UA: UA?.Price,
      GB: GB?.Price,
      CA: Canada?.Price,
    });

    const discountToGrid = (discount: PriceInfo[]) => {
      return discount.map((d) => ({
        from: d.kg_from,
        to: d.kg_to,
        price: d.Price_by_kg,
      }));
    };

    commit("setDeliveryRatesGrid", {
      UA: discountToGrid(UA?.Discount ?? []),
      USA: discountToGrid(USA?.Discount ?? []),
      GB: discountToGrid(GB?.Discount ?? []),
      CA: discountToGrid(Canada?.Discount ?? []),
    });
  },
  async updateBaseData({ dispatch }: any) {
    await Promise.all([
      dispatch("user/loadUserData", null, { root: true }),
      dispatch("user/loadLastUpdates", null, { root: true }),
      dispatch("cards/loadSavedCards", null, { root: true }),
      dispatch("parcels/loadParcelList", null, { root: true }),
      dispatch("parcels/loadInnerPackages", null, { root: true }),
    ]);
  },
  async loadRefferalTariffs({ commit }: any) {
    const data = await gerRefferalsPrice();

    commit("setRefferalRates", data);
  },
};

// mutations
const mutations = {
  setLanguage: (state: any, payload: Locales) => {
    state.language = payload;
    i18n.locale = payload;
    window.localStorage.setItem("currLang", payload);
  },
  setRates: (state: any, payload: any) => {
    state.dutyFreeLimit = payload.dutyFreeLimit;
    state.freeTaxLimit = payload.freeTaxLimit;
    state.customsService = payload.customsService;
  },
  setSettings: (state: any, payload: any) => {
    state.buyoutPercent = payload.buyoutPercent;
    state.combinePrice = payload.combinePrice;
    state.dividePrice = payload.dividePrice;
    state.consultPrice = payload.consultPrice;
    state.photoPrice = payload.photoPrice;
    state.repackPrice = payload.repackPrice;
    state.insurancePercent = payload.insurancePercent;
    state.minBuyoutService = payload.minBuyoutService;
    state.minDeliveryTime = payload.minDeliveryTime;
    state.maxDeliveryTime = payload.maxDeliveryTime;
    state.insuranceMinCost = payload.insuranceMinCost;
    state.volumeCoff = payload.volumeCoff;
  },
  setOnline: (state: any, payload: boolean) => {
    state.isOnline = payload;
  },
  setConnectionError: (state: any, payload: boolean) => {
    state.isConnectionError = payload;
  },
  setPopupActive: (state: any, payload: boolean) => {
    state.isPopupActive = payload;
  },
  setDeliveryRates: (state: any, payload: any) => {
    state.deliveryRates = payload;
  },
  setDeliveryRatesGrid: (state: any, payload: any) => {
    state.deliveryRatesGrid = payload;
  },
  setRefferalRates: (state: any, payload: any) => {
    state.refferalRates = payload;
  },
  setCanMakePayments: (state: any, payload: any) => {
    state.isCanMakePayments = payload;
  },
  updateHelpData: (state: any, payload: any) => {
    state.helpData = {
      ...state.helpData,
      ...payload,
    };
  },
  setAppGuideActive: (state: any, payload: any) => {
    window.localStorage.setItem("isAppGuideDisabled", payload);
    state.isAppGuideActive = payload;
  },
  addOpenMessage: (state: any, payload: number[]) => {
    state.openMessages = [...state.openMessages, [...payload]];
  },
  removeOpenMessage: (state: any, payload: number) => {
    state.openMessages = [
      ...state.openMessages.filter((msg: number) => msg !== payload),
    ];
  },
  setPushNotification: (state: any, payload: any) => {
    state.pushNotification = payload;
  },
  setSharedProductId: (state: any, payload: any) => {
    state.sharedProductId = payload;
  },
  setSharedShopId: (state: any, payload: any) => {
    state.sharedShopId = payload;
  },
  setUserIPData: (state: any, payload: any) => {
    state.userIPData = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
