/* eslint-disable */
import i18n from "@/i18n";
import { appState } from "@/store/appstate";
import eventsRegister from "./events-register";
import store from "@/store";

const apiURL = process.env.VUE_APP_API_URL;
export const apiUIURL = process.env.VUE_APP_API_UI_URL;
const firebaseApiKey = process.env.VUE_APP_FIREBASE;
const webSocketURL = process.env.VUE_APP_SOCKET_URL;

let vueRoot: any;
export function setVueRoot(vueRootParam: any) {
  vueRoot = vueRootParam;
}

export function getRequestAuthHeader(): string {
  return `Bearer ${store.getters["user/userToken"]}`;
}

export enum ErrorType {
  CONN = "CONN", // Connection error (no internet)
  AUTH = "AUTH", // 401
  SERVER = "SERVER", // 500
}

// Universal requestCreator. Create request, catch error and reSend request
const INTERVAL_SHORT = 1000; // ms, 1s
const INTERVAL_LONG = 5000; // ms, 5s
// errorCallback will never called
export function createRequest(
  name: string,
  data = {} as any,
  onSuccess: Function,
  onServerError?: Function
) {
  // call repeater function with connection error callback
  repeatRequest(
    name,
    data,
    onSuccess,
    () => {
      // server error. TODO: check server message
      // vueRoot.$emit(eventsRegister.addMessage, { type: 'error', message: i18n.t('serverError') })
      if (onServerError) onServerError();
    },
    () => {} // TODO: onConnError null?
  );
}

export function repeatRequest(
  name: string,
  data = {} as any,
  onSuccess: Function,
  onServerError: Function,
  onConnError: Function,
  errConnCounter = 1
) {
  switch (name) {
    case "objList":
      // sendRequest(`${apiURL}/token`, 'POST', data, onSuccess, ()=>{
      fetchObjectsListNew(data, onSuccess, onServerError, () => {
        // connection error
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "objList",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "objList",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "objEdit":
      // sendRequest(`${apiURL}/token`, 'POST', data, onSuccess, ()=>{
      sendEditObject(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "objEdit",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "objEdit",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "objState":
      // sendRequest(`${apiURL}/token`, 'POST', data, onSuccess, ()=>{
      sendObjState(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "objState",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "objState",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "initData":
      loadAppData(
        "/easyget/appdata",
        { Mode: 1 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "initData",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "initData",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "initDataArchive":
      loadAppData(
        "/easyget/appdata",
        { Mode: 1, Version: 1, IsArchive: 1 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "initData",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "initData",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "werehouses":
      loadAppData(
        "/easyget/appdata",
        { Mode: 1024, Version: 1, IsArchive: 0 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "werehouses",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "werehouses",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "parcels":
      loadAppData(
        "/easyget/appdata",
        { Mode: 32, Version: 1, IsArchive: 1 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "parcels",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "parcels",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;
    case "sharedparcels":
      loadAppData(
        "/easyget/appdata",
        { Mode: 4 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "sharedparcels",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "sharedparcels",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;
    case "innerPackages":
      loadAppData(
        "/easyget/appdata",
        { Mode: 512, Version: 1, IsArchive: 0 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "parcels",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "parcels",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "settings":
      loadAppData(
        "/easyget/appdata",
        { Mode: 256, Version: 1, IsArchive: 0 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "settings",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "settings",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "parcelsarchive":
      loadAppData(
        "/easyget/appdata",
        { Mode: 32, Version: 1, IsArchive: 1 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "parcels",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "parcels",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "lastUpdates":
      loadAppData(
        "/easyget/appdata",
        { Mode: 8, Lang_id: store.getters["root/userLangCode"] },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "lastUpdates",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "lastUpdates",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "lastUpdatesArchive":
      loadAppData(
        "/easyget/appdata",
        { Mode: 8, Version: 1, IsArchive: 1 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "lastUpdatesArchive",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "lastUpdatesArchive",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "address":
      loadAppData(
        "/easyget/appdata",
        { Mode: 128 },
        onSuccess,
        onServerError,
        () => {
          // err callback
          if (errConnCounter > 3) {
            // show connection error and run long interval requests
            store.commit("root/setConnectionError", true);
            setTimeout(() => {
              repeatRequest(
                "address",
                null,
                (responseData: any) => {
                  store.commit("root/setConnectionError", false);
                  onSuccess(responseData);
                },
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_LONG);
            // if (onError) { onError() } // TODO: check typeof onError === "function"
          } else {
            // repeat request
            setTimeout(() => {
              // run short interval requests
              repeatRequest(
                "address",
                null,
                onSuccess,
                onServerError,
                onConnError,
                errConnCounter + 1
              );
            }, INTERVAL_SHORT);
          }
        }
      );
      break;

    case "savedCards":
      getSavedCards(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "savedCards",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "savedCards",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "createPayment":
      createPayment(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "createPayment",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "createPayment",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "cancelPayment":
      cancelPayment(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "cancelPayment",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "cancelPayment",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "defaultCard":
      setDefaultCard(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "defaultCard",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "defaultCard",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "deleteCard":
      deleteCard(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "deleteCard",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "deleteCard",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "newCardIntent":
      getPaymentIntentNewCard(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "newCardIntent",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "newCardIntent",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "cancelNewCardIntent":
      cancelSetupIntent(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "cancelNewCardIntent",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "cancelNewCardIntent",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "packageDeliveryCost":
      packageDeliveryCost(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "packageDeliveryCost",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "packageDeliveryCost",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "shoppingCost":
      shoppingCost(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "shoppingCost",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "shoppingCost",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "createShopping":
      createShopping(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "createShopping",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "createShopping",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "sendFiles":
      sendFiles(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "sendFiles",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "sendFiles",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "deliveryCost":
      getDeliveryCost(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "deliveryCost",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "deliveryCost",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "tokenFCM":
      sendTokenFCM(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "deliveryCost",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "deliveryCost",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "deleteParcel":
      deleteObject(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "deleteParcel",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "deleteParcel",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;

    case "divideParcel":
      divideParcel(data, onSuccess, onServerError, () => {
        // err callback
        if (errConnCounter > 3) {
          // show connection error and run long interval requests
          store.commit("root/setConnectionError", true);
          setTimeout(() => {
            repeatRequest(
              "deliveryCost",
              data,
              (responseData: any) => {
                store.commit("root/setConnectionError", false);
                onSuccess(responseData);
              },
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_LONG);
          // if (onError) { onError() } // TODO: check typeof onError === "function"
        } else {
          // repeat request
          setTimeout(() => {
            // run short interval requests
            repeatRequest(
              "deliveryCost",
              data,
              onSuccess,
              onServerError,
              onConnError,
              errConnCounter + 1
            );
          }, INTERVAL_SHORT);
        }
      });
      break;
  }
}

// TODO: get vueRoot and delete vueRoot from function params
function sendRequest(
  url: string,
  type: string,
  requestData: any,
  callbackSuccess: Function,
  callbackError?: Function,
  appRoot?: any
) {
  let xhr = new XMLHttpRequest();
  xhr.open(type, url);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) callbackSuccess(data);
    else if (xhr.status === 401 && callbackError)
      callbackError({ type: ErrorType.AUTH });
    else {
      // server error or 404
      if (callbackError) {
        if (data) callbackError({ type: ErrorType.SERVER, data: data });
        else callbackError({ type: ErrorType.SERVER });
      }
      // if (appRoot) appRoot.$emit(eventsRegister.addMessage, { type: 'error', message: lcl.serverError })
    }
  };
  xhr.onerror = () => {
    if (callbackError) callbackError({ type: ErrorType.CONN });
    // if (appRoot) appRoot.$emit(eventsRegister.addMessage, { type: 'error', message: lcl.serverError })
  };
  if (requestData) xhr.send(JSON.stringify(requestData));
  else xhr.send();
}

export function sendLoginData(
  formAuthData: any,
  deviceData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  // xhr.open('POST',`${apiURL}/easyget/token`)
  xhr.open("POST", `${apiURL}/easyget/v1/token`);
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.setRequestHeader("X-U-DISPLAY-RESOLUTION", deviceData.screenSize);
  xhr.setRequestHeader("X-U-DISPLAY-DPI", deviceData.dpi);
  xhr.onreadystatechange = (e) => {
    if (xhr.readyState === 4) {
      let data = JSON.parse(xhr.responseText);
      if (xhr.status === 200) callbackSuccess(data);
      else callbackError(data);
    }
  };
  xhr.onerror = (err: any) => {
    callbackError();
  };
  xhr.send(JSON.stringify(formAuthData));
}

// Send user login event
export function checkIn() {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/checkin`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.send(null);
}

// Registration/signUp
export function signUp(
  requestData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/signup`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) callbackSuccess(data);
    else if (xhr.status === 401) store.dispatch("user/logout");
    else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(requestData));
}

export function sendCodeSMS(
  requestData: any,
  deviceData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/signup/verification/smscode`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.setRequestHeader("X-U-DISPLAY-RESOLUTION", deviceData.screenSize);
  xhr.setRequestHeader("X-U-DISPLAY-DPI", deviceData.dpi);
  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(requestData));
}

// Reset password
export function sendPhoneForgot(
  requestData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/recovery/pas`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("x-u-app-ver", store.getters["root/appVersion"]);
  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(requestData));
}

export function sendCodeConfirmForget(
  requestData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/recovery/confirm/code`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(requestData));
}

export function sendNewPass(
  requestData: any,
  deviceData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/recovery/newpas`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.setRequestHeader("X-U-DISPLAY-RESOLUTION", deviceData.screenSize);
  xhr.setRequestHeader("X-U-DISPLAY-DPI", deviceData.dpi);
  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(requestData));
}

function loadAppData(
  path: string,
  params: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}${path}`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", store.getters["root/lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      try {
        let parsedData = JSON.parse(xhr.responseText);
        callbackSuccess(parsedData);
      } catch (e) {
        console.log("Can not parse received data!");
        console.log(e);
        callbackError("Error");
      }
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(params));
}

function updateAppData(
  path: string,
  params: any,
  callbackSuccess: Function,
  notAuthCallback: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}${path}`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      try {
        let parsedData = JSON.parse(xhr.responseText);
        callbackSuccess(parsedData);
      } catch (e) {
        console.log("Can not parse received data!");
        console.log(e);
        callbackError("Error");
      }
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = (err: any) => {
    callbackError();
  };
  xhr.send(JSON.stringify(params));
}

/* #AppData #modes
1 - appdata Только главная страница
4 - Parcels
8  - EventsLog
16 - Package (parcels new)
32 - Выкуп
64 - Notifications
128 - Address data
*/

// Forgot password
export function sendMail(
  dataForSend: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/password/forget`);
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onreadystatechange = (e) => {
    if (xhr.readyState === 4) {
      let data = JSON.parse(xhr.responseText);
      if (xhr.status === 200) {
        callbackSuccess(data);
      } else {
        let dateNow = new Date();
        let dateTimeStr =
          dateNow.getHours() +
          ":" +
          dateNow.getMinutes() +
          ":" +
          dateNow.getSeconds();
        callbackError(data);
      }
    }
  };
  xhr.send(JSON.stringify(dataForSend));
}

export function getCargoTrackInfo(
  track: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  // xhr.open('POST',`https://dev-my.utec-l.com:9006/nobrand/tracking?tracking=${track}`)
  xhr.open("POST", `${apiURL}/easyget/tracking?tracking=${track}`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onreadystatechange = (e) => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        let data = JSON.parse(xhr.responseText);
        callbackSuccess(data);
      } else if (xhr.status === 401) {
        store.dispatch("user/logout");
      } else {
        callbackError();
      }
    }
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(null);
}

export function sendChangedPass(
  passData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/pas/change`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) callbackSuccess(data);
    else if (xhr.status === 401) store.dispatch("user/logout");
    else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(passData));
}

export function addFunds(
  fundsData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiUIURL}/payment/addfunds`);
  // xhr.open('POST',`https://my.utec-l.com:9007/payment/addfunds`)
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(fundsData));
}

// load user pay meta  data
export function loadCardMeta(
  queryParams: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();

  xhr.open(
    "GET",
    `${apiUIURL}/payment/easyget/stripe/metadata/confirm${queryParams}`
  );
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError(data);
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send();
}

// Create payment intent
export function createPayment(
  fundsData: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiUIURL}/payment/api/v1/easyget/stripe/create/pay`);
  // xhr.open('POST',`${apiUIURL}/payment/addfunds`)
  // xhr.open('POST',`https://my.utec-l.com:9007/payment/addfunds`)
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(fundsData));
}

export function cancelPayment(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiUIURL}/payment/easyget/stripe/payment-intent/cancel`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function getPaymentIntentNewCard(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiUIURL}/payment/api/v1/easyget/stripe/setup-intent`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function cancelSetupIntent(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("DELETE", `${apiUIURL}/payment/api/v1/easyget/stripe/setup-intent`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function getConvertedPayment(
  payVal: number,
  counryCode: number,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "GET",
    `${apiUIURL}/payment/currencyrate?amount_usd=${payVal}&pay_country_id=${counryCode}`
  );
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send();
}

export function getSavedCards(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `${apiUIURL}/payment/api/v1/easyget/stripe/payment-intent/cards`
  );
  // xhr.open('POST',`${apiUIURL}/payment/easyget/stripe/cards`)
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function setSecureCard(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `${apiUIURL}/payment/easyget/stripe/payment-intent/card/select`
  );
  // xhr.open('POST',`${apiUIURL}/payment/easyget/stripe/cards`)
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function setDefaultCard(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "PUT",
    `${apiUIURL}/payment/api/v1/easyget/stripe/payment-methods/def`
  );
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}
export function deleteCard(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "DELETE",
    `${apiUIURL}/payment/api/v1/easyget/stripe/payment-methods`
  );
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function getPaymentInfo(
  data: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/cost-itrade-list`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(data));
}

export function fetchMetaSysList(
  classId: number,
  callbackSuccess: Function,
  callbackError?: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", `${apiURL}/workplace/objectlist/directory?id=${classId}`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    }
  };
  xhr.onerror = (err) => {
    if (callbackError) callbackError();
  };
  xhr.send();
}

export const getTarrifsData = async () => {
  try {
    const res = await fetch(`${apiURL}/easyget/cost-accounting/tariff`, {
      method: "POST",
      headers: {
        authorization: getRequestAuthHeader(),
        "Accept-Language": appState["lang"],
      },
    });
    return await res.json();
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const deleteAccount = async () => {
  try {
    const res = await fetch(`${apiURL}/easyget/v1/account/delete`, {
      method: "POST",
      headers: {
        authorization: getRequestAuthHeader(),
        "Accept-Language": appState["lang"],
      },
    });
    return await res.json();
  } catch (e) {
    console.log("Error: ", e);
  }
};

export const getRefferalData = async () => {
  try {
    const res = await fetch(`${apiURL}/easyget/objectlist/get`, {
      method: "POST",
      headers: {
        authorization: getRequestAuthHeader(),
        "Accept-Language": store.getters["root/lang"],
        "X-U-APP-VER": store.getters["root/appVersion"],
      },
      body: JSON.stringify({
        ClassList: [
          {
            Class_id: 23860,
            TypeLoad: 1,
            needLinkObject: 0,
            nPage: 1,
            sizePage: 10000,
          },
        ],
      }),
    });
    return await res.json();
  } catch (e) {
    console.log("Error: ", e);
  }
};

export function getMetadata(
  callbackSuccess: Function,
  notAuthCallback: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", `${apiURL}/workplace/metadata`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onreadystatechange = (e) => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        try {
          let data = JSON.parse(xhr.responseText);
          callbackSuccess(data);
        } catch (e) {
          console.log("Can not parse received metadata!");
          console.log(e);
          callbackError("Error");
        }
      } else if (xhr.status === 401) {
        store.dispatch("user/logout");
      } else {
        console.log("Error: ", xhr.status, xhr.statusText);
        callbackError("Error");
      }
    }
  };
  xhr.send(null);
}

// Get objects list. Univarsal API
export function fetchObjectsList(
  requestData: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/objectlist/get`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(requestData));
}

export function fetchObjectsListNew(
  requestData: any,
  callbackSuccess: Function,
  errorCallback: Function,
  onConnError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/objectlist/get`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else errorCallback();
  };
  xhr.onerror = (err: any) => {
    onConnError();
  };
  xhr.send(JSON.stringify(requestData));
}

// Set obj data. Univarsal API
export function sendEditObject(
  requestData: any,
  callbackSuccess: Function,
  errorCallback: Function,
  onConnError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/objectlist`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else errorCallback();
  };
  xhr.onerror = (err: any) => {
    onConnError();
  };
  xhr.send(JSON.stringify(requestData));
}

// Delete obj. Universal api
export function deleteObject(
  requestData: any,
  callbackSuccess: any,
  callbackError: any,
  onConnError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/objectlist/delete`);
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else if (xhr.status === 500) {
      callbackError(data);
    } else callbackError();
  };
  xhr.onerror = (err) => {
    onConnError();
  };
  xhr.send(JSON.stringify(requestData));
}

// Update objState. Universal API
export function sendObjState(
  requestData: any,
  callbackSuccess: Function,
  errorCallback: Function,
  onConnError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/objectlist/cs`);
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else errorCallback();
  };
  xhr.onerror = (err: any) => {
    onConnError();
  };
  xhr.send(JSON.stringify(requestData));
}

// Create delivery address with contacts. Spec API
// TODO: add to create request and remove notAuthCallback
export function sendDeliveryAddress(
  requestData: any,
  callbackSuccess: Function,
  notAuthCallback: Function,
  serverErrorCallback: Function,
  onConnError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/customer/delivery-address`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  xhr.onload = () => {
    let data: any;
    if (xhr.responseText) data = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      callbackSuccess(data);
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else if (xhr.status === 500) {
      serverErrorCallback(data);
    } else serverErrorCallback();
  };
  xhr.onerror = (err) => {
    onConnError();
  };
  xhr.send(JSON.stringify(requestData));
}

export function fetchPackagePhotoList(
  cargoId: number,
  callbackSuccess: any,
  callbackError: any,
  callbackConnError: any
): void {
  let xhr = new XMLHttpRequest();
  // xhr.open('GET',`${apiURL}/cabinet/cargo/photo?Id=${cargoId}`)
  xhr.open("GET", `${apiURL}/easyget/package/photo?Id=${cargoId}`); // Id=2285344 or Id=2285344&CargoCode="werty"
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      let data = JSON.parse(xhr.responseText);
      callbackSuccess(data);
    } else if (xhr.status === 500) {
      callbackError(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = callbackConnError;
  xhr.send();
}

// Get/set package delivery cost
export function packageDeliveryCost(
  requestData: any,
  callbackSuccess: Function,
  callbackError: any,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/calc-delivery-cost`);
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

export function shoppingCost(
  requestData: any,
  callbackSuccess: any,
  callbackError: any,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/cost-itrade-list`);
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

// Get setup data
export function getSetup(requestData: any, callbackSuccess: any): void {
  let idsStr = requestData.join(";"); // from [1,2,3] to '1;2;3'
  sendRequest(
    `${apiURL}/easyget/settings/setupvalue?id=${idsStr}`,
    "GET",
    null,
    callbackSuccess
  );
}

// Get delivery tarif
export function getDeliveryCost(
  requestData: any,
  callbackSuccess: Function,
  callbackError: any,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/cost-accounting/tariff`);
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

// Get address list USA
export async function findAddressListUSA(search: string) {
  let url = `${apiURL}/easyget/autocomplete/street-address/usa?search=${search}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        authorization: getRequestAuthHeader(),
        "Accept-Language": appState["lang"],
      },
    });
    const json = await res.json();
    return json.results || [];
  } catch (e) {
    console.log("Error: ", e);
  }
}

// Get address list UK
export async function findAddressListGB(search: string, country: string) {
  let url = `${apiURL}/easyget/autocomplete/street-address/${country}?input=${search}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        authorization: getRequestAuthHeader(),
        "Accept-Language": appState["lang"],
      },
    });

    const json = await res.json();
    return json || [];
  } catch (e) {
    console.log("Error: ", e);
  }
}

export async function getAddressDetailsGB(publicId: string, country: string) {
  // Only encode the query parameter value
  const encodedPublicId = encodeURIComponent(publicId);
  let url = `${apiURL}/easyget/autocomplete/details/${country}?public_id=${encodedPublicId}`;

  try {
    const res = await fetch(url, {
      // Use the url directly without encoding the whole URL
      method: "GET",
      headers: {
        authorization: getRequestAuthHeader(),
        "Accept-Language": appState["lang"],
      },
    });

    if (!res.ok) {
      // Changed to check if response is not ok
      console.error("Error response:", res);
      return []; // Or handle error as needed
    }

    const json = await res.json();
    return json || [];
  } catch (e) {
    console.error("Error in getAddressDetailsGB:", e);
    return []; // Or handle error as needed
  }
}

// Message bot notification
export function unsubscribe(
  requestData: any,
  callbackSuccess: any,
  callbackError: any,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `${apiURL}/easyget/settings/notification/telegram/unsubscribe`
  );
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) callbackSuccess();
    else if (xhr.status === 500) callbackError();
    else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

// Create new shopping. Send form data
export function createShopping(
  file: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/shopping/uploadfile`);
  xhr.timeout = 240000; // ms, 240sec, 4min
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess();
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(file);
}

export function deleteShopping(
  requestData: any,
  callbackSuccess: any,
  callbackError: any,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("DELETE", `${apiURL}/easyget/shopping/delete`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      let data = JSON.parse(xhr.responseText);
      callbackSuccess(data);
    } else if (xhr.status === 500) {
      callbackError(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

// Divide parcel
export function divideParcel(
  requestData: any,
  callbackSuccess: any,
  callbackError: any,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/package/repack`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      let data = JSON.parse(xhr.responseText);
      callbackSuccess(data);
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

// Combine parcel
export function combineParcel(
  requestData: any,
  callbackSuccess: any,
  callbackError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `${apiURL}/api/v1.1/easyget/cm/uni/edit?tag=sm_pack_package`
  );
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      let data = JSON.parse(xhr.responseText);
      callbackSuccess(data);
    } else callbackError();
  };
  // xhr.onerror = onConnError
  xhr.send(JSON.stringify(requestData));
}

// Tracking details parcel
export function getTrackingDetails(
  requestData: any,
  callbackSuccess: any,
  callbackError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `${apiURL}/api/v1.1/easyget/cm/uni/get?tag=sm_api_track_parcels_itrade`
  );
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      let data = JSON.parse(xhr.responseText);
      callbackSuccess(data);
    } else callbackError();
  };
  // xhr.onerror = onConnError
  xhr.send(JSON.stringify(requestData));
}

// Send Device Firebase cloud messaging token
export function sendTokenFCM(
  requestData: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/api/v1.1/easyget/fcm/client/app/key`);
  xhr.setRequestHeader("authorization", getRequestAuthHeader());
  xhr.setRequestHeader("Accept-Language", appState["lang"]);
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      callbackError();
    } else callbackError();
  };
  xhr.onerror = onConnError;
  xhr.send(JSON.stringify(requestData));
}

// Send binary img
export function sendFiles(
  formData: any,
  callbackSuccess: Function,
  callbackError: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open("POST", `${apiURL}/easyget/objectlist/file`);
  xhr.timeout = 240000; // ms, 240sec, 4min
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else if (xhr.status === 401) {
      store.dispatch("user/logout");
    } else callbackError();
    // let msg = xhr.responseText ? JSON.parse(xhr.responseText ) : ''
    // serverErrorCallback( msg )
  };
  xhr.onerror = onConnError;
  xhr.send(formData);
}

// send files archive
export function archiveFiles(
  qeryParams: any,
  formData: any,
  callbackSuccess: Function,
  serverErrorCallback: Function,
  onConnError: any
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `${apiURL}/easyget/file/archive?property=${qeryParams.propertyId}&object=${qeryParams.objectId}&class=${qeryParams.classId}`
  );
  xhr.setRequestHeader("Authorization", getRequestAuthHeader());
  // xhr.setRequestHeader("X-U-APP-VER", store.getters["root/appVersion"]);

  xhr.onload = () => {
    if (xhr.status === 200) callbackSuccess(JSON.parse(xhr.responseText));
    else {
      let msg = xhr.responseText ? JSON.parse(xhr.responseText) : "";
      serverErrorCallback(msg);
    }
  };
  xhr.onerror = onConnError;
  xhr.send(formData);
}

export function getFirebaseDynamicLink(
  options: any,
  callbackSuccess: Function,
  callbackError: Function
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseApiKey}`
  );
  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    } else callbackError();
  };
  xhr.onerror = (err) => {
    callbackError();
  };
  xhr.send(JSON.stringify(options));
}

// Send Data to Firebase Realtime DB
export function sendFirebaseDB(
  requestData: any,
  callbackSuccess: () => void
): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "POST",
    `https://easygetcloudmessage.firebaseio.com/new-year-users.json`
  );
  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess();
    }
  };
  xhr.send(JSON.stringify(requestData));
}

export function getFirebaseDB(callbackSuccess: (data: any) => void): void {
  let xhr = new XMLHttpRequest();
  xhr.open(
    "GET",
    `https://easygetcloudmessage.firebaseio.com/new-year-users.json`
  );
  xhr.onload = () => {
    if (xhr.status === 200) {
      callbackSuccess(JSON.parse(xhr.responseText));
    }
  };
  xhr.send();
}
