import { getSavedCards } from "../../services/requests-list";

const getDefaultState = () => ({
    cardList: [],
    isBankCardsLoaded: true,
})

// initial state
const state = getDefaultState()

// getters
const getters = {
    cardList: (state: any) => state.cardList,
    isBankCardsLoaded: (state: any) => state.isBankCardsLoaded
}

// actions
const actions = {
    async loadSavedCards({ commit, rootGetters }: any) {
        commit('loadStart')
        const res: any = await getSavedCards(rootGetters["user/userId"]).catch((res: any) => res)
        if (res.Cards) commit('setCards', res.Cards)
        commit('loadSuccess')
    },
}

// mutations
const mutations = {
    setCards(state: any, payload: any) {
        state.cardList = payload
    },
    loadSuccess(state: any) {
        state.isBankCardsLoaded = true
    },
    loadStart(state: any) {
        state.isBankCardsLoaded = false
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}