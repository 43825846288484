<template>
  <div id="app" class="web-container">
    <router-view />

    <notifier />
    <push-popup />
    <portal-target
      name="easy-bottom"
      transition="fade-transition"
    ></portal-target>
  </div>
</template>

<script>
import Notifier from "@/components/Notifier.vue";
import { mapGetters, mapMutations } from "vuex";
import { getParameterByName } from "./utils/utils";
import PushPopup from "./components/PushPopup.vue";

export default {
  name: "App",
  components: {
    Notifier,
    PushPopup,
  },
  created() {
    this.onDeviceReady();
    this.setAppLang();
  },
  computed: {
    ...mapGetters("root", ["lang"]),
  },
  methods: {
    ...mapMutations("user", ["setRefferalCode"]),
    ...mapMutations("root", ["setPushNotification", "setOnline"]),
    setAppLang() {
      if (!window.localStorage.getItem("currLang")) {
        window.localStorage.setItem("currLang", this.lang);
      }
      this.$moment.locale(this.lang);
    },
    initPushHandler() {
      const onSuccces = (data) => {
        if (data.DynamicLink) {
          this.setPushNotification({
            type: "inner",
            title: data.Title,
            body: data.Msg,
            ...JSON.parse(data.DynamicLink),
          });
        }
      };
      window.cordova.plugins.firebase.messaging.requestPermission().then(() => {
        window.cordova.plugins.firebase.messaging.onMessage(onSuccces);
        window.cordova.plugins.firebase.messaging.onBackgroundMessage(
          onSuccces
        );
      });
    },
    checkDynamicLinks() {
      const onHandleLink = (data) => {
        if (data) {
          const refferal = getParameterByName("refferal", data.deepLink);
          // const sharedProductId = getParameterByName("product", data.deepLink);
          // const sharedShopId = getParameterByName(
          //   "sharedShopId",
          //   data.deepLink
          // );

          if (refferal) {
            this.setRefferalCode(refferal);
          }
          // if (sharedProductId) setAppState("sharedProductId", sharedProductId);
          // if (sharedShopId) {
          //   setAppState("sharedShopId", sharedShopId);
          //   setRoute("shopSuggest");
          // }
        } else {
          console.log("App wasn't started from a dynamic link");
        }
      };
      const dynamiclinks = window["cordova"].plugins.firebase.dynamiclinks;
      if (dynamiclinks) {
        dynamiclinks
          .getDynamicLink()
          .then((data) => onHandleLink(data, "from getDynamicLink"));
        dynamiclinks.onDynamicLink((data) =>
          onHandleLink(data, "from onDynamicLink")
        );
      }
    },
    enableAdvertiserTracking() {
      window.facebookConnectPlugin?.setAdvertiserTrackingEnabled(
        true,
        function() {
          console.log("setAdvertiserTrackingEnabled success");
        },
        function() {
          console.error("setAdvertiserTrackingEnabled failure");
        }
      );
    },
    onDeviceReady() {
      document.addEventListener(
        "deviceready",
        () => {
          const { platformId } = window.cordova;
          //set platform specific class
          document.body.classList.add(platformId);

          window.addEventListener("keyboardWillShow", () =>
            document.body.classList.add("with-keyboard")
          );
          window.addEventListener("keyboardWillHide", () =>
            document.body.classList.remove("with-keyboard")
          );

          // navigator.connection
          document.addEventListener(
            "offline",
            () => {
              this.setOnline(false);
            },
            false
          );
          document.addEventListener(
            "online",
            () => {
              this.setOnline(true);
            },
            false
          );
          this.checkDynamicLinks();
          this.initPushHandler();
          this.enableAdvertiserTracking();
        },
        false
      );
    },
  },
};
</script>
