/* eslint-disable */
import { appData, ParcelItem } from '@/store/appdata'
import { appState } from '@/store/appstate'
import { equals } from '@/utils/check-data-equality' // TODO: use JSON stringify and check string representation of data

export function setAppState(key: string, val: any) {
	//@ts-ignore
	appState[key] = val
}

export function updateAppData(key: string, value: any): void {
	//@ts-ignore
	appData[key] = value
}

export function setBaseAppData(newData: any): any {
	if (newData.sm_customer) appData.sm_customer = newData.sm_customer[0]
	else console.log('Error! App had not received required data: sm_customer')
	if (newData.sm_move_money)  appData.sm_move_money = newData.sm_move_money
	// optional data
	if (newData.sm_log && !appData.sm_log.length) appData.sm_log = newData.sm_log // update only empty logEventList. Reason: full logEventList may be already loaded by getLastUpdatesData
	if (newData.sm_currency_rate) appData.sm_currency_rate = newData.sm_currency_rate
}


export function setOneAppData(newData: any): any {
	Object.keys(newData).forEach((dataKey: string)=>{
		//@ts-ignore
		if ( equals(appData[dataKey], newData[dataKey]) ) return false
		//@ts-ignore
		appData[dataKey] = newData[dataKey]
	})
}

export function updateOneAppData(newData: any, callback?: any): any {
	let dataKey = Object.keys(newData)[0]
	if (newData.sm_order_parcels) { // update parcels data
    	if ( equals(appData.sm_order_parcels, newData.sm_order_parcels) ) return false
    	if ( !appState.isParcelsEditModeActive ) // Update parcels only if parcelsTable NOT in editMode (parcelsEditMode is false)
    		appData.sm_order_parcels = newData.sm_order_parcels
	} else if (Object.keys(newData).length) { // if data not empty - simple set new data by key
		//@ts-ignore
		if ( equals(appData[dataKey], newData[dataKey]) ) return false
		//@ts-ignore
		appData[dataKey] = newData[dataKey]
		if (callback) callback()
	}
}


export function removeParcel(parcelId: number) {
	appData.sm_order_parcels = appData.sm_order_parcels.filter((parcelItem: ParcelItem)=>{
		if (parcelItem.ID === parcelId) return false
		return true
	})
}



export function clearAllData() { // Logout clean data
	let curDataItemVal: any
	for (let appDataItemKey in appData) {
		//@ts-ignore
		curDataItemVal = appData[appDataItemKey]
		if ( Array.isArray(curDataItemVal) ) {
			//@ts-ignore
			appData[appDataItemKey] = []
		} else if (typeof curDataItemVal === 'number') {
			//@ts-ignore
			appData[appDataItemKey] = 0
		} else if (typeof curDataItemVal === 'string') {
			//@ts-ignore
			appData[appDataItemKey] = ''
		} else { // Object
			//@ts-ignore
			appData[appDataItemKey] = {}
		}
	}
}