/* eslint-disable */
// Acces token sample:  { access_token: 'ead45s...', 'expires_in: 300, token_type: 'Bearer' }
interface AuthData {
	'access_token': string
	'expires_in': number // miliSeconds
	'token_type': string
}

let authData: AuthData = <AuthData> {}
export function getAuthData(): AuthData {
	return authData
}

let ls: any = window.localStorage
declare var NativeStorage: any;
export function checkUserAuthAsync(callback: any): void {
    let dateTimeNow: any = new Date
    let dateTimeNowMS: any = dateTimeNow.getTime()
    let expiresDatSavedMS: any
    NativeStorage.getItem("expiresDateMS", (value: any)=>{
        expiresDatSavedMS = value
        let authDataSaved: any
        NativeStorage.getItem("authData", (authDataStorage: any)=>{
            authDataSaved = authDataStorage
            if (dateTimeNowMS < parseInt(expiresDatSavedMS)) {
                if (authDataSaved) {
                    authData = JSON.parse(authDataSaved)
                    callback(true)
                } else callback(false)
            }
            callback(false)
        }, ()=>{
            console.log("Error get authData");
            callback(false)
        })
    }, ()=>{
        console.log("Error get expiresDateMS");
        callback(false)
    })
}


export function checkUserAuth(): boolean {
	let dateTimeNow = new Date()
	let dateTimeNowMS = dateTimeNow.getTime()
	// let expiresDatMS = (authDataResponse.expires_in * 1000) + dateTimeNow
	let expiresDatSavedMS: any = ls['expiresDateMS']
	let authDataSaved: any = ls['authData']
	if (expiresDatSavedMS &&
		dateTimeNowMS < parseInt( expiresDatSavedMS )) {
		if ( authDataSaved) {
			authData = JSON.parse( authDataSaved )
			return true
		}
		return false
	}
	return false
}

export function saveAuthDataLocal(authDataResponse: AuthData): void {
	let dateTimeNow = new Date()
	let dateTimeNowMS = dateTimeNow.getTime()
	let expiresDateMS = authDataResponse.expires_in + dateTimeNowMS
	ls.setItem("expiresDateMS", expiresDateMS, ()=>{}, ()=>{
            console.log("Error save expiresDateMS")
        })
	authData = authDataResponse
	ls.setItem("authData", JSON.stringify(authData), ()=>{}, ()=>{
            console.log("Error save authData")
        })
}

export function removeAuthDataLocal(): boolean {
	ls.remove("expiresDateMS", ()=>{}, ()=>{ console.log("Error remove expiresDateMS") } )
	ls.remove("authData", ()=>{}, ()=>{ console.log("Error remove authData") } )
	// if ( 'expiresDateMS' in ls ) return false
	return true
}