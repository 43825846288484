import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Vue2TouchEvents from "vue2-touch-events";
//@ts-ignore
import Transitions from "vue2-transitions";
import VScrollLock from "v-scroll-lock";
import PortalVue from "portal-vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import AppLayout from "@/layouts/AppLayout.vue";
import "@/styles/main.scss";
// eslint-disable-next-line
const moment = require("moment");
//@ts-ignore
import VueQrcode from "@chenfengyuan/vue-qrcode";
import { numberRound, truncate } from "./utils/filters";

Vue.config.productionTip = false;

Vue.component("AppLayout", AppLayout);
Vue.use(Vue2TouchEvents);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(VScrollLock);
Vue.use(PortalVue);
Vue.use(Transitions);
// eslint-disable-next-line
Vue.use(require("vue-moment"), {
  moment,
});

Vue.filter("truncate", truncate);
Vue.filter("numberRound", numberRound);

Vue.component(VueQrcode.name, VueQrcode);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
