export function truncate(text: string, length: number, clamp: string) {
  clamp = clamp || "...";
  const node = document.createElement("div");
  node.innerHTML = text;
  const content = node.textContent || "";
  return content.length > length ? content.slice(0, length) + clamp : content;
}

export function numberRound(n: number, d = 2): number {
  const multiplicator = Math.pow(10, d);
  n = parseFloat((n * multiplicator).toFixed(11));
  const test = Math.round(n) / multiplicator;
  return +test.toFixed(d);
}
