export enum FacebookEvents {
    EVENT_NAME_SUBMIT_APPLICATION = "SubmitApplication",
    EVENT_NAME_SUBSCRIBE = "Subscribe",
    EVENT_NAME_UNLOCKED_ACHIEVEMENT = "fb_mobile_achievement_unlocked",
    EVENT_NAME_ACHIEVED_LEVEL = "fb_mobile_level_achieved",
    EVENT_NAME_ACTIVATED_APP = "fb_mobile_activate_app",
    EVENT_NAME_ADDED_PAYMENT_INFO = "fb_mobile_add_payment_info",
    EVENT_NAME_ADDED_TO_CART = "fb_mobile_add_to_cart",
    EVENT_NAME_ADDED_TO_WISHLIST = "fb_mobile_add_to_wishlist",
    EVENT_NAME_COMPLETED_REGISTRATION = "fb_mobile_complete_registration",
    EVENT_NAME_COMPLETED_TUTORIAL = "fb_mobile_tutorial_completion",
    EVENT_NAME_DEACTIVATED_APP = "fb_mobile_deactivate_app",
    EVENT_NAME_INITIATED_CHECKOUT = "fb_mobile_initiated_checkout",
    EVENT_NAME_PURCHASED = "fb_mobile_purchase",
    EVENT_NAME_PUSH_TOKEN_OBTAINED = "fb_mobile_obtain_push_token",
    EVENT_NAME_RATED = "fb_mobile_rate",
    EVENT_NAME_SEARCHED = "fb_mobile_search",
    EVENT_NAME_SESSION_INTERRUPTIONS = "fb_mobile_app_interruptions",
    EVENT_NAME_SPENT_CREDITS = "fb_mobile_spent_credits",
    EVENT_NAME_VIEWED_CONTENT = "fb_mobile_content_view",
    EVENT_NAME_CONTACT = "Contact",
    EVENT_NAME_FIND_LOCATION = "FindLocation",
    EVENT_PARAM_CONTENT_ID = "fb_content_id",
    EVENT_PARAM_CONTENT_TYPE = "fb_content_type",
    EVENT_PARAM_CURRENCY = "fb_currency",
    EVENT_PARAM_DESCRIPTION = "fb_description",
    EVENT_PARAM_LEVEL = "fb_level",
    EVENT_PARAM_MAX_RATING_VALUE = "fb_max_rating_value",
    EVENT_PARAM_NUM_ITEMS = "fb_num_items",
    EVENT_PARAM_PAYMENT_INFO_AVAILABLE = "fb_payment_info_available",
    EVENT_PARAM_REGISTRATION_METHOD = "fb_registration_method",
    EVENT_PARAM_SEARCH_STRING = "fb_search_string",
    EVENT_PARAM_SOURCE_APPLICATION = "fb_mobile_launch_source",
    EVENT_PARAM_SUCCESS = "fb_success",
    EVENT_PARAM_VALUE_NO = "0",
    EVENT_PARAM_VALUE_YES = "1",
}

export enum FacebookParams {
    CURRENCY = "fb_currency",
    REGISTRATION_METHOD = "fb_registration_method",
    CONTENT_TYPE = "fb_content_type",
    CONTENT = "fb_content",
    CONTENT_ID = "fb_content_id",
    SEARCH_STRING = "fb_search_string",
    SUCCESS = "fb_success",
    MAX_RAING_VALUE = "fb_max_rating_value",
    PAYMENT_INFO_AVAILABLE = "fb_payment_info_available",
    NUM_ITEMS = "fb_num_items",
    LEVEL = "fb_level",
    DESCRIPTION = "fb_description",
    LAUNCH_SOURCE = "fb_mobile_launch_source",
    AD_TYPE = "ad_type",
    ORDER_ID = "fb_order_id",
}