import { getInnerPackages, getParcelList, getSharedParcelList, toggleShareByProduct } from "../../services/requests-list";

const getDefaultState = () => ({
    parcelList: [],
    innerPackagesList: [],
    sharedParcelList: [],
    archiveParcelList: [],
})

// initial state
const state = getDefaultState()

// getters
const getters = {
    parcelList: (state: any) => state.parcelList,
    innerPackagesList: (state: any) => state.innerPackagesList,
    archiveParcelList: (state: any) => state.archiveParcelList,
    wholeParcelList: (state: any, getters: any) => [...getters.parcelList, ...getters.archiveParcelList],
    parcelById: (state: any, getters: any) => (id: number) => getters.wholeParcelList.find((parcel: any) => {
        return [parcel.iParcel_id, parcel.id, parcel.Package_id].includes(+id)
    }) || {},
    isHasCombineParcels: (state: any) => {
        const parcelsToCombine = state.parcelList.filter(
            (p: any) => [5020].includes(p.p_State_id) || [5020].includes(p.State_id)
        );
        const parcelsCN = parcelsToCombine.filter((p: any) => p.pr_587 === "CN");
        const parcelsUSA = parcelsToCombine.filter((p: any) => p.pr_587 === "US");

        return {
            CN: parcelsCN.length >= 2,
            US: parcelsUSA.length >= 2,
        };
    },
    sharedParcelList: (state: any,  getters: any) => getters.wholeParcelList.filter((p: any) => p.Rate_11834 > 3),
}

// actions
const actions = {
    async loadInnerPackages({ commit }: any) {
        const res = await getInnerPackages().catch((res: any) => res)
        commit('setInnerPackagesList', res)
    },
    async loadParcelList({ commit }: any) {
        const res: any = await getParcelList().catch((res: any) => res);
        if (res.sm_parcels_itrade) {
            commit('setParcelList', res.sm_parcels_itrade)
        }
    },
    async loadArchiveParcelList() {
        // const res: any = await getArchiveParcelList().catch((res: any) => res);
        // if (res.sm_parcels_itrade) {
        //     commit('updateParcelList', res.sm_parcels_itrade)
        // }
    },
    async toggleShareParcel({ commit }: any, { id, value }: any) {
        await toggleShareByProduct(id, value).catch((res: any) => res);
        commit('updateParcel', { id, pr830: value });
    },

    async loadSharedParcelList({ commit }: any) {
        const res: any = await getSharedParcelList();
        if (res.sm_parcels_itrade_rate) {
            commit('setSharedParcelList', res.sm_parcels_itrade_rate)
        }
    }
}

// mutations
const mutations = {
    setParcelList(state: any, parcelList: any) {
        state.parcelList = parcelList
    },
    setSharedParcelList(state: any, list: any) {
        state.sharedParcelList = list;
    },
    updateParcelList(state: any, parcelList: any) {
        // state.parcelList = [...state.parcelList, ...parcelList]
        state.parcelList = parcelList
    },
    updateParcel(state: any, parcel: any) {
        state.parcelList = [...state.parcelList.map((item: any) => ![item.id, item.iParcel_id].includes(+parcel.id) ? item : { ...item, ...parcel })];
    },
    setInnerPackagesList(state: any, list: any) {
        state.innerPackagesList = list
    },
    setArchiveParcelList(state: any, parcelList: any) {
        state.archiveParcelList = parcelList
    },
    resetState(state: any) {
        Object.assign(state, getDefaultState())
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}