































import Vue from "vue";
import { mapGetters } from "vuex";
import { openExternal } from "../utils/utils";

export interface PushNotification {
  buttonName?: string;
  routing?: string;
  type?: string;
  title: string;
  body: string;
  imgPath?: string;
  url?: string;
  titlePopup?: string;
  textPopup?: string;
  imgPathPopup?: string;
}

export default Vue.extend({
  name: "PushPopup",
  data() {
    return {
      isOpened: false,
      notificationData: {
        // type: "inner",
        // route: "shops",
        // buttonName: "Подробней",
        // title: "Кращий додаток для доставки вантажів",
        // body:
        //   "Компания Universal намерена отложить премьеру фантастической киноленты «Мир Юрского периода 3: Власть». Как выяснилось, картина не выйдет в июне следующего года – её представят зрителям годом позже. На протяжении последних нескольких месяцев мне выпала честь работать с отличной актёрской командой и съёмочной группой над картиной, премьеры которой мы с нетерпением ожидаем. Мы очень хотим поделиться с миром этой историей. Однако придётся ещё немного запастись терпением – это стоит того.",
        // imgPath: "https://i.ibb.co/k6c8bFR/QQ.png",
      } as any,
    };
  },
  computed: {
    ...mapGetters("root", ["pushNotification"]),
    isScrollLocked(): boolean {
      if(document.body.classList.contains("web")) return false;
      return this.isOpened;
    }
  },
  mounted() {
    this.$watch(
      "pushNotification",
      (newData: PushNotification) => {
        if (!newData) return false;
        if(newData.type === "inner") {
            this.isOpened = true;
            this.notificationData = {
            ...newData,
            };
        }
      },
      { deep: true, immediate: true }
    );
  },
  methods: {
    onClose() {
      this.isOpened = false;
    },
    onFinish() {
      this.isOpened = false;

      if (this.notificationData.url) {
        openExternal(this.notificationData.url);
        return false;
      }
      if (this.notificationData.routing) {
        this.$router.push(this.notificationData.routing)
        return false;
      }
    },
  },
});
